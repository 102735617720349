import { theme as base } from '@chakra-ui/react';

const { Divider: DividerBase } = base.components;

export const Divider = {
    baseStyle: {
        bg: 'greyScheme.200',
        opacity: 1,
        mt: 4,
        mb: 4
    },
    variants: {
        'with-opacity': {
            ...DividerBase.variants.solid,
            borderColor: 'greyScheme.600',
            opacity: 0.5
        }
    }
};
