import React from 'react';
import { Separator } from '../../../../components/Separator';
import { Text, VStack } from '@chakra-ui/react';
import { ActionButton, ActionButtonVariant } from '../ActionButton';
import { useCallToConfirmOrderMutation, useCallToTurnTabletOnMutation } from '../../../../api/service';

interface CallButtonsProps {
    order: any;
}

export const CallButtons = ({ order }: CallButtonsProps) => {
    const [callToTurnTabletOn, { isLoading: isCallingToTurnTablet }] = useCallToTurnTabletOnMutation();
    const [callToConfirmOrder, { isLoading: isCallingToConfirmOrder }] = useCallToConfirmOrderMutation();

    const { restaurant, id: orderId } = order;
    const { id: restaurantId } = restaurant;

    return (
        <>
            <Separator />
            <VStack w="full" pt={5} pb={2} pl={2} spacing={4}>
                <ActionButton
                    variant={ActionButtonVariant.restaurant}
                    disabled={isCallingToTurnTablet}
                    info={'Call the restaurant with automated voice mail.'}
                    onClick={() => callToTurnTabletOn({ restaurantId, orderId })}
                >
                    <Text pt={1} fontSize={12} pl={2} pr={3} fontWeight="bold">
                        Ask Restaurant to turn on the tablet
                    </Text>
                </ActionButton>
                <ActionButton
                    variant={ActionButtonVariant.restaurant}
                    disabled={isCallingToConfirmOrder}
                    info="Call the restaurant with automated voice mail."
                    onClick={() => callToConfirmOrder({ restaurantId, orderId })}
                >
                    <Text pt={1} fontSize={12} pl={2} pr={3} fontWeight="bold">
                        Ask Restaurant to confirm the order
                    </Text>
                </ActionButton>
            </VStack>
        </>
    );
};
