import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    anyPass,
    findIndex,
    forEachObjIndexed,
    insert,
    isEmpty,
    isNil,
    keys,
    omit,
    pickBy,
    reject,
    toPairs,
    without
} from 'ramda';
import { liveOrdersApi } from './api';
import { combineOrders, handleOrderChange, mapIdToQueryParam, sortOrderKeysByDate } from './utils';
import {
    Order,
    OrderActionPayload,
    OrderCallRequestChangedPayload,
    OrderConfirmationChangedPayload,
    Orders,
    OrdersState
} from './types';

const matchGetFilteredOrdersSuccess = liveOrdersApi.endpoints.getFilteredOrders.matchFulfilled;

const sliceName = 'orders';

const initialState: OrdersState = {
    liveOrdersIds: [],
    liveOrdersById: {},
    areLiveOrdersLoading: true,
    filteredOrdersIds: [],
    filteredOrdersById: {},
    filters: {},
    isFilterView: false
};

const ordersSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        orderCallRequestChanged(state, action: PayloadAction<OrderCallRequestChangedPayload>) {
            const { orderId, callRestaurant } = action.payload;
            state.liveOrdersById[orderId].call_restaurant = callRestaurant;
        },
        orderConfirmationChanged(state, action: PayloadAction<OrderConfirmationChangedPayload>) {
            const { orderId, confirmed } = action.payload;
            state.liveOrdersById[orderId].confirmed = confirmed;
        },
        orderChanged: (state, action: PayloadAction<OrderActionPayload>) => {
            const { id, details } = action.payload;
            handleOrderChange(id, details, state);
            state.liveOrdersById[id] = details;
        },
        orderAdded: (state, action: PayloadAction<OrderActionPayload>) => {
            const { id, details } = action.payload;
            const { belongs_to_order, created_at } = details;

            state.liveOrdersById[id] = details;
            const isLinkedOrder = !isNil(belongs_to_order);

            if (isLinkedOrder) {
                state.liveOrdersIds = without([belongs_to_order], state.liveOrdersIds);
                handleOrderChange(id, details, state);
            }
            const createdAtTimestamp = isLinkedOrder
                ? Date.parse(state.liveOrdersById[belongs_to_order].created_at)
                : Date.parse(created_at);

            const idToInsert = isLinkedOrder ? belongs_to_order + id : id;

            state.liveOrdersIds = insert(
                findIndex(
                    (a) => Date.parse(state.liveOrdersById[a].created_at) < createdAtTimestamp,
                    state.liveOrdersIds
                ),
                idToInsert,
                state.liveOrdersIds
            );
        },
        orderRemoved: (state, action: PayloadAction<OrderActionPayload>) => {
            const { id } = action.payload;
            state.liveOrdersById = omit([id], state.liveOrdersById);
            state.liveOrdersIds = without([id], state.liveOrdersIds);
        },
        initializeOrders: (state, action: PayloadAction<Orders>) => {
            const orders = action.payload ?? {};
            const extendedOrders = toPairs(
                pickBy<Orders, Orders>(({ extended_by_order }) => !isNil(extended_by_order), orders)
            );
            const combinedOrders = extendedOrders.map(([key, value]) => {
                const combinedOrder = combineOrders(value, orders[value.extended_by_order]);
                const combinedOrderKey = key + value.extended_by_order;
                return [combinedOrderKey, combinedOrder];
            }) as [string, Order][];

            const displayKeys = Object.keys(orders)
                .filter((key) => {
                    const { extended_by_order, belongs_to_order } = orders[key];
                    return isNil(extended_by_order) && isNil(belongs_to_order);
                })
                .concat(combinedOrders.map(([key]) => key));

            state.liveOrdersById = { ...orders, ...Object.fromEntries(combinedOrders) };
            state.liveOrdersIds = sortOrderKeysByDate(displayKeys, state.liveOrdersById);
            state.areLiveOrdersLoading = false;
        },
        updateFilters: (state, action) => {
            state.filters = reject(anyPass([isEmpty, isNil]))(action.payload);
            forEachObjIndexed((value, id) => {
                const query = mapIdToQueryParam(id);
                if (!isNil(query)) {
                    state.filters[query] = state.filters[id];
                }
                delete state.filters[id];
            }, state.filters);

            state.isFilterView = !isEmpty(state.filters);
        },
        clearFilters: (state) => {
            state.filters = {};
            state.isFilterView = false;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(matchGetFilteredOrdersSuccess, (state, { payload }) => {
            state.filteredOrdersIds = keys(payload) as string[];
            state.filteredOrdersById = payload;
        });
    }
});
export const {
    orderConfirmationChanged,
    orderCallRequestChanged,
    updateFilters,
    clearFilters,
    orderChanged,
    orderRemoved,
    orderAdded,
    initializeOrders
} = ordersSlice.actions;

export const watchLiveOrders = createAction(`${sliceName}/watchLiveOrders`);
export const unwatchLiveOrders = createAction(`${sliceName}/unwatchLiveOrders`);

export const ordersReducer = ordersSlice.reducer;
