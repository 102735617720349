import React, { FunctionComponent } from 'react';
import { OrderTableRow } from '../../../../components/OrderTable/OrderTableRow';
import { Address, CreatedTime, Customer, LinkId, Restaurant } from './cells';

interface PendingOrderRowProps {
    orderId: string;
}

type Props = PendingOrderRowProps;

const PendingRow: FunctionComponent<Props> = ({ orderId }) => {
    return (
        <OrderTableRow>
            <LinkId orderId={orderId} />
            <Restaurant orderId={orderId} />
            <Address orderId={orderId} />
            <Customer orderId={orderId} />
            <CreatedTime orderId={orderId} />
        </OrderTableRow>
    );
};

export default PendingRow;
