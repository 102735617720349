export const Text = {
    baseStyle: {
        color: 'fonts.primary',
        fontWeight: 'normal',
        fontSize: 'lg'
    },
    // TODO consider to add most commonly used variants
    variants: {
        alert: {
            fontSize: 'sm',
            color: 'fonts.alert',
            fontWeight: 'bold',
            textAlign: 'left'
        },
        infoPopover: {
            color: 'gray4',
            fontSize: 'md'
        }
    }
};
