import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const HotelIcon = createIcon({
    displayName: 'HotelIcon',
    viewBox: '0 0 27 30',
    path: (
        <path
            fill="currentColor"
            d="M25.627 12.854L13.845.44a1.133 1.133 0 00-.884-.433 1.114 1.114 0 00-.884.433L.373 12.854c-.785.929-.226 2.535.884 2.535h1.08v11.467c0 .834.326 1.634.906 2.223.58.59 1.367.921 2.187.921h15.16a3.04 3.04 0 001.182-.24 3.08 3.08 0 001.002-.681 3.18 3.18 0 00.9-2.222V15.388h1.08c1.148 0 1.659-1.636.873-2.535zm-7.08 12.665h-3.033v-5.2h-5.037v5.2H7.453V12.545h3.024v5.14h5.037v-5.14h3.034v12.974z"
        />
    )
});
