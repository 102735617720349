export const Alert = {
    baseStyle: {
        container: {
            mt: {
                sm: '0',
                base: '-60px'
            }
        }
    }
};
