import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const RequestedSupportIcon = createIcon({
    displayName: 'RequestedSupportIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 5.37C0 13.4374 6.5626 20 14.63 20C17.0392 20 19 18.0401 19 15.63C19 15.3849 18.9791 15.1417 18.9392 14.9071C18.9002 14.6686 18.8414 14.4368 18.7635 14.2126C18.6571 13.9038 18.3977 13.672 18.0795 13.5989L12.4127 12.2984C12.103 12.2299 11.7743 12.3164 11.5425 12.5387C11.4076 12.6679 11.4038 12.6717 10.7863 13.8506C8.7628 12.9291 7.10505 11.2771 6.15125 9.2118C7.32925 8.59525 7.3321 8.5924 7.4613 8.4575C7.6836 8.2257 7.77385 7.8989 7.70165 7.5873L6.4011 1.9215C6.32795 1.6023 6.0952 1.34295 5.78645 1.2356C5.56035 1.1577 5.32855 1.0988 5.0977 1.06175C4.86115 1.0209 4.617 1 4.37 1C1.95985 1 0 2.95985 0 5.37ZM2 5.4375C2 4.09312 3.09312 3 4.4375 3C4.53969 3 4.64094 3.00656 4.745 3.01969L5.73875 7.35187C5.57656 7.43625 5.39937 7.52906 5.23531 7.61531C4.42719 8.03719 3.78969 8.37094 4.12438 9.10687C5.22594 12.2437 7.75625 14.775 10.8556 15.8625C11.644 16.1799 11.9536 15.5893 12.3803 14.7752L12.3819 14.7722C12.4387 14.6622 12.4992 14.5469 12.5586 14.4339C12.5893 14.3754 12.6197 14.3175 12.6491 14.2612L16.9803 15.255C16.9934 15.3562 17 15.4584 17 15.5625C17 16.9069 15.9059 18 14.5625 18C7.63531 18 2 12.3638 2 5.4375Z"
                fill="currentColor"
            />
            <circle xmlns="http://www.w3.org/2000/svg" cx="15" cy="5" r="5" fill="#FF3312" />
        </>
    )
});
