import React from 'react';
import { CustomerMenuBody } from '../../components/MenuPopover/CustomerMenuBody';
import {
    useSendCustomerUnreachableEmailMutation,
    useSendDelayEmailMutation,
    useSendFailEmailMutation,
    useSendFraudEmailMutation
} from 'api/service';
import { MenuPopover } from 'components/MenuPopover';

interface CustomerPopoverProps {
    children: React.ReactNode;
    customer: {
        id: string;
        email: string;
        phone: string;
    };
    orderId: string;
}

export const CustomerPopover = ({ customer, orderId, children }: CustomerPopoverProps) => {
    const [sendCustomerUnreachableEmail] = useSendCustomerUnreachableEmailMutation();
    const [sendDelayEmail] = useSendDelayEmailMutation();
    const [sendFraudEmail] = useSendFraudEmailMutation();
    const [sendFailEmail] = useSendFailEmailMutation();

    const userId = customer.id;

    const body = (
        <CustomerMenuBody
            customer={customer}
            items={[
                {
                    label: 'e-mail: Slight delay',
                    onClick: () => sendDelayEmail({ orderId, userId }),
                    info: (
                        <>
                            Send email to this client saying that{' '}
                            <strong>the delivery may take longer than usual.</strong>
                        </>
                    )
                },
                {
                    label: 'e-mail: Fraud',
                    onClick: () => sendFraudEmail({ orderId, userId }),
                    info: (
                        <>
                            Send email to this client saying that{' '}
                            <strong>their most recent transaction has been blocked.</strong>
                        </>
                    )
                },
                {
                    label: 'e-mail: Failed to contact',
                    onClick: () => sendFailEmail({ orderId, userId }),
                    info: (
                        <>
                            Send email to this client saying that{' '}
                            <strong>their food has been left outside the property.</strong>
                        </>
                    )
                },
                {
                    label: 'e-mail: Cannot get through',
                    onClick: () => sendCustomerUnreachableEmail({ orderId, userId }),
                    info: (
                        <>
                            Send email to this address saying <strong>we&apos;re outside but cannot get through</strong>{' '}
                            to them on phone.
                        </>
                    )
                }
            ]}
        />
    );

    return <MenuPopover body={body}>{children}</MenuPopover>;
};
