import { createStandaloneToast } from '@chakra-ui/toast';
import { theme } from '../theme';

export const createErrorToast = (title: string, status: number): void => {
    const toast = createStandaloneToast({
        theme
    });

    toast({
        title,
        description: `Error status: ${status}`,
        status: 'error',
        position: 'bottom-left',
        duration: 5000,
        isClosable: true
    });
};
