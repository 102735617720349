import * as React from 'react';
import { DeliveryStatus as DeliveryStatusEnum, OrderKind } from '../../../../../types';
import { DeliveryStatus } from 'components/DeliveryStatus';
import { toPairs } from 'ramda';
import { Flex, Text } from '@chakra-ui/layout';

export const ORDER_DESCRIPTION = {
    [OrderKind.preSet]: 'Order was pre-set',
    [OrderKind.feast]: 'FEAST order',
    [OrderKind.remote]: "Order placed from restaurant's website",
    [OrderKind.butler24]: 'Butler24 Food Order',
    [OrderKind.hotel]: 'Hotel order',
    [OrderKind.corporate]: 'Corporate Order',
    [OrderKind.vivaCity]: 'VIVA City Order'
};

export const DELIVERY_STATUS_LABELS = {
    [DeliveryStatusEnum.handledByRestaurant]: 'Delivery handled by the restaurant',
    [DeliveryStatusEnum.processPending]: 'Delivery process pending',
    [DeliveryStatusEnum.pickupInProgress]: 'Pickup in progress',
    [DeliveryStatusEnum.pickupDone]: 'Pickup done delivery in progress',
    [DeliveryStatusEnum.completed]: 'Delivery completed',
    [DeliveryStatusEnum.cancelled]: 'There exists Stuart cancellation with "package not ready" reason'
};

export const DELIVERY_STATUSES_LEGEND = toPairs(DELIVERY_STATUS_LABELS).map(([status, label]) => (
    <Flex align="center" key={label}>
        <DeliveryStatus status={DeliveryStatusEnum[status]} />
        <Text variant="infoPopover" ml={2}>
            {label}
        </Text>
    </Flex>
));
