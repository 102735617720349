import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { DeviceLiveStatus } from './slice';
import { RestaurantDeviceStatus } from './types';

export const selectDeviceLiveStatusState = (state: RootState): DeviceLiveStatus => state.deviceLiveStatus;

export const selectDeviceLiveStatusByRestaurantId = createSelector(
    selectDeviceLiveStatusState,
    (state) => state.byRestaurantId
);

export const makeSelectDeviceStatus = (restaurantId: string) =>
    createSelector(selectDeviceLiveStatusByRestaurantId, (byRestaurantId) => {
        const status = byRestaurantId[restaurantId];
        return status ?? RestaurantDeviceStatus.unavailable;
    });
