import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { PendingOrdersState } from './slice';
import { makeSelectLiveOrder } from '../orders/selectors';

const pendingOrdersSelector = (state: RootState): PendingOrdersState => state.pendingOrders;

export const selectPendingOrderIds = createSelector(pendingOrdersSelector, (state) => state.liveOrdersIds);
export const selectPendingOrdersById = createSelector(pendingOrdersSelector, (state) => state.pendingOrdersById);

export const makeSelectPendingOrder = (orderId: string) =>
    createSelector(selectPendingOrdersById, (pendingOrders) => pendingOrders[orderId] ?? {});

export const makeSelectPendingIdInfo = (orderId: string) =>
    createSelector(makeSelectPendingOrder(orderId), (order) => {
        const { human_id } = order;
        return { human_id };
    });

export const makeSelectPendingRestaurant = (orderId: string) =>
    createSelector(makeSelectPendingOrder(orderId), (order) => {
        const { restaurant } = order;

        return {
            restaurantId: restaurant.id,
            name: restaurant.name
        };
    });

export const makeSelectPendingAddressInfo = (orderId: string) =>
    createSelector(makeSelectPendingOrder(orderId), (order) => order.address);

export const makeSelectPendingCustomer = (orderId: string) =>
    createSelector(makeSelectPendingOrder(orderId), (order) => {
        const { user = {} } = order;
        return {
            ...user,
            note: user.note_by_admin ?? ''
        };
    });
export const makeSelectPendingCreationDate = (orderId: string) =>
    createSelector(makeSelectPendingOrder(orderId), (order) => order.created_at);
