import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const NotesIcon = createIcon({
    displayName: 'NotesIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 0H15C15.552 0 16 0.448 16 1V14V16V17C16 17.552 15.552 18 15 18H14H3C1.346 18 0 16.654 0 15V3C0 1.346 1.346 0 3 0ZM3 2H14V12H3C2.647 12 2.314 12.072 2 12.184V3C2 2.449 2.449 2 3 2ZM3 16C2.449 16 2 15.551 2 15C2 14.449 2.449 14 3 14H14V16H3Z"
                fill="currentColor"
            />
            <mask
                id="mask0_72:341"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="18"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 0H15C15.552 0 16 0.448 16 1V14V16V17C16 17.552 15.552 18 15 18H14H3C1.346 18 0 16.654 0 15V3C0 1.346 1.346 0 3 0ZM3 2H14V12H3C2.647 12 2.314 12.072 2 12.184V3C2 2.449 2.449 2 3 2ZM3 16C2.449 16 2 15.551 2 15C2 14.449 2.449 14 3 14H14V16H3Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_72:341)" />
        </>
    )
});
