import { isNil } from 'ramda';
import { loginRequest, setTokens } from '../../api';
import { configureApi } from '../../api/config';

export const signIn = async (userName: string, password: string) => {
    const { data }: any = await loginRequest(userName, password);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { auth_token: accessToken, user_id: userId } = data;

    if (isNil(accessToken)) {
        throw Error('[signIn] accessToken is undefined');
    }

    setTokens(accessToken, userId, userName);
    configureApi(accessToken);
};
