import * as React from 'react';
import { Box, Flex, Center, SimpleGrid, IconButton, Spacer, Button } from '@chakra-ui/react';
import { Icon } from '../Icon';
import { Link, useHistory } from 'react-router-dom';
import { useIsMobileView } from '../../hooks';

export interface TopBarProps {
    showGoBack?: boolean;
}

export const TopBar = ({ showGoBack }: TopBarProps) => {
    const history = useHistory();
    const isMobileView = useIsMobileView();

    const onGoBack = () => history.push('/dashboard');

    return (
        <Box w="100%" py={4} px={isMobileView ? 5 : 6}>
            <SimpleGrid columns={3}>
                {showGoBack ? (
                    <Flex align="center">
                        <IconButton icon={<Icon name="BackIcon" />} aria-label="Go back" onClick={onGoBack} />
                    </Flex>
                ) : (
                    <Spacer />
                )}
                <Center>
                    <Icon name="FeastLogoIcon" size={10} />
                </Center>

                <Flex justify="end" align="center">
                    <Button fontWeight="bold" mr={2}>
                        <Link to="/logout">Logout</Link>
                    </Button>
                </Flex>
            </SimpleGrid>
        </Box>
    );
};

TopBar.defaultProps = {
    variant: 'desktop',
    showGoBack: false
};
