import React, { FunctionComponent } from 'react';
import { VStack } from '@chakra-ui/react';
import { Expense } from '../../../../components/Expense/Expense';
import { Separator } from '../../../../components/Separator';
import { formatPrice } from '../../../../utils';

interface PriceComponentsProps {
    order: any;
}

type Props = PriceComponentsProps;

const PriceComponents: FunctionComponent<Props> = ({ order }) => {
    const {
        original_price = 0,
        delivery_fee = 0,
        discount_value = 0,
        hotel_service_fee = 0,
        admin_fee = 0,
        grand_total_price = 0
    } = order?.price_components ?? {};

    return (
        <>
            <VStack w="full" justifyContent="flex-start" spacing={0} py={1} pl={4} pr={10} alignItems="flex-start">
                <Expense item="Food" cost={formatPrice(original_price)} />
                <Expense item="Delivery fee" cost={formatPrice(delivery_fee)} />
                <Expense item="Discount" cost={formatPrice(discount_value)} />
                <Expense item="Service fee" cost={formatPrice(admin_fee)} />
                <Expense item="Hotel Commission" cost={formatPrice(hotel_service_fee)} />
                <Expense item="Total" cost={formatPrice(grand_total_price)} />
            </VStack>
            <Separator />
        </>
    );
};

export default PriceComponents;
