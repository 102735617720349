import React, { useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFilterView } from '../../slices/orders/selectors';
import { clearFilters } from '../../slices/orders/slice';
import { useFormContext } from 'react-hook-form';

export const ClearFiltersButton = () => {
    const isFilterView = useSelector(selectIsFilterView);
    const { reset } = useFormContext();
    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
        dispatch(clearFilters());
        reset();
    }, [dispatch]);

    if (isFilterView) {
        return (
            <Button position="absolute" variant="solid" top={-10} right={0} onClick={handleClick}>
                Clear Filters
            </Button>
        );
    }
    return <></>;
};
