/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { UserCredential } from '@firebase/auth';
import type { FunctionKeys } from 'utility-types';
import { EmailCredentials } from '../models';

export interface AuthState {
    userId: string | null;
    email: string | null;
    emailVerified: boolean;
    isAnonymous: boolean;
    displayName: string | null;
    photoURL: string | null;
    loading: boolean;
}

const initialState: AuthState = {
    userId: null,
    email: null,
    emailVerified: false,
    isAnonymous: false,
    displayName: null,
    photoURL: null,
    loading: false
};

/* eslint-disable no-param-reassign */
const authSlice = createSlice({
    name: 'firebaseAuth',
    initialState,
    reducers: {
        signInWithEmailAndPassword: (state, _action: PayloadAction<EmailCredentials>) => void (state.loading = true),
        signInWithEmailAndPasswordError: (state, _action: PayloadAction<Error>) => void (state.loading = false),
        signInWithEmailAndPasswordSuccess: (_state, action: PayloadAction<UserCredential>) =>
            firebaseCredentialsToState(action.payload),
        signInWithGooglePopup: (state) => void (state.loading = true),
        signInWithGooglePopupError: (state, _action: PayloadAction<Error>) => void (state.loading = false),
        signInWithGooglePopupSuccess: (_state, action: PayloadAction<UserCredential>) =>
            firebaseCredentialsToState(action.payload),
        signOut: (state) => void (state.loading = true),
        signOutError: (state, _action: PayloadAction<Error>) => void (state.loading = false),
        signOutSuccess: () => initialState,
        createUserWithEmailAndPassword: (state, _action: PayloadAction<EmailCredentials>) =>
            void (state.loading = true),
        createUserWithEmailAndPasswordError: (state, _action: PayloadAction<Error>) => void (state.loading = false),
        createUserWithEmailAndPasswordSuccess: (_state, action: PayloadAction<UserCredential>) =>
            firebaseCredentialsToState(action.payload)
    }
});
/* eslint-enable no-param-reassign */

export type ActionTypes = FunctionKeys<typeof authSlice.actions>;

export const {
    signInWithEmailAndPassword,
    signInWithEmailAndPasswordError,
    signInWithEmailAndPasswordSuccess,
    signInWithGooglePopup,
    signInWithGooglePopupError,
    signInWithGooglePopupSuccess,
    signOut,
    signOutError,
    signOutSuccess,
    createUserWithEmailAndPassword,
    createUserWithEmailAndPasswordError,
    createUserWithEmailAndPasswordSuccess
} = authSlice.actions;

export const firebaseAuthReducer = authSlice.reducer;

const firebaseCredentialsToState = ({
    user: { uid, email, emailVerified, isAnonymous, displayName, photoURL }
}: UserCredential): AuthState => ({
    userId: uid,
    email,
    emailVerified,
    isAnonymous,
    displayName,
    photoURL,
    loading: false
});
