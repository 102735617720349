import { theme as base } from '@chakra-ui/react';

const { Button: ButtonBase } = base.components;

const solidButtonBaseStyle = {
    bg: 'yellowScheme.100',
    _hover: {
        bg: 'yellowScheme.200'
    },
    _active: {
        bg: 'yellowScheme.100'
    },
    fontWeight: 'bold'
};

export const Button = {
    baseStyle: {
        color: 'blue',
        fontSize: 'lg',
        _focus: {
            boxShadow: 'none'
        }
    },
    variants: {
        outline: {
            borderColor: 'blue',
            borderRadius: 'buttons.sm',
            height: 'button.height.outline'
        },
        solid: {
            ...solidButtonBaseStyle,
            borderRadius: 'buttons.lg',
            height: 'button.height.solid',
            fontSize: 'sm'
        },
        login: {
            ...ButtonBase.variants.solid,
            ...solidButtonBaseStyle,
            bg: 'yellowScheme.50',
            height: 'button.height.login',
            borderRadius: 'buttons.sm',
            _active: {
                bg: 'yellowScheme.50'
            },
            _hover: {
                bg: 'yellowScheme.200',
                _disabled: {
                    bg: 'yellowScheme.50',
                    opacity: 0.9
                }
            },
            _disabled: {
                bg: 'yellowScheme.50',
                opacity: 0.9
            }
        },
        'login-ghost': {
            ...ButtonBase.variants.ghost,
            bg: 'transparent',
            height: 'button.height.loginGhost',
            color: 'yellowScheme.50',
            fontWeight: 'bold',
            fontSize: 'md',
            p: 0,
            _hover: {
                bg: 'transparent',
                color: 'yellowScheme.100'
            },
            _active: {
                bg: 'transparent',
                color: 'yellowScheme.50'
            }
        },
        menu: {
            ...solidButtonBaseStyle,
            borderRadius: 'none',
            height: 'button.height.menu',
            bg: 'transparent',
            fontWeight: 'normal',
            fontSize: 'lg',
            justifyContent: 'flex-start',
            _hover: {
                bg: 'yellowScheme.200'
            },
            _active: {
                bg: 'yellowScheme.100'
            }
        }
    },
    defaultProps: {
        variant: 'ghost'
    }
};
