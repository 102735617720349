import React from 'react';
import { Button, Center, Container, HStack, Image, Text } from '@chakra-ui/react';
import { LoginForm } from './components/LoginForm';
import feastLogo from '../../assets/feast-logo.png';
import feastText from '../../assets/feast-text.png';
import { FeastBottomStrokes, FeastUpperStrokes, FeastOval } from './components';

export const LoginPage = () => {
    return (
        <Center h="100vh" w="full" bg="gradients.loginPage.background" p={0} position="relative" overflow="hidden">
            <Container maxW="loginPage.form.maxW" align="center" zIndex="1" p={0}>
                <Image src={feastLogo} />

                <Image mt={10} mb={20} src={feastText} />

                <Text color="fonts.white" fontSize="2xl" mb={12} lineHeight={6}>
                    Hi! Login or Signup to get started!
                </Text>

                <LoginForm />

                <HStack justify="space-between" mt={8}>
                    <Button variant="login-ghost">Forgotten password?</Button>

                    <HStack>
                        <Text color="fonts.white" fontSize="md" lineHeight={6}>
                            No account?
                        </Text>
                        <Button variant="login-ghost">Sign up</Button>
                    </HStack>
                </HStack>
            </Container>

            <FeastUpperStrokes />
            <FeastBottomStrokes />

            <FeastOval />
        </Center>
    );
};
