import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const BackIcon = createIcon({
    displayName: 'BackIcon',
    viewBox: '0 0 23 20',
    path: (
        <path
            fill="currentColor"
            d="M21.429 8.571H4.479l5.19-6.228A1.428 1.428 0 107.474.514L.331 9.086c-.055.067-.084.145-.125.22-.035.06-.076.111-.102.177a1.408 1.408 0 00-.103.511L0 10l.001.006c0 .174.039.347.103.511.026.066.067.117.102.177.041.074.07.153.125.22l7.143 8.572a1.427 1.427 0 002.012.183 1.428 1.428 0 00.183-2.012l-5.19-6.229h16.95a1.43 1.43 0 000-2.857z"
        />
    )
});
