import React from 'react';
import { isNil } from 'ramda';
import { Box, Stack, Text } from '@chakra-ui/react';
import { formatPrice, formatRefundsDateTime } from '../../../../utils';

interface RefundProps {
    refund: any;
}
export const Refund = ({ refund }: RefundProps) => {
    const { id, description, amount, created_at } = refund ?? {};

    return (
        <>
            {!isNil(id) && (
                <Box>
                    <Stack direction="row">
                        <Text fontWeight="bold">{formatPrice(amount)}</Text>
                        <Text>On {formatRefundsDateTime(created_at)}</Text>
                    </Stack>
                    <Text>Reason: {description}</Text>
                </Box>
            )}
        </>
    );
};
