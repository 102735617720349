export const Select = {
    variants: {
        outline: {
            field: {
                borderColor: 'transparent',
                bg: 'greyScheme.500',
                boxShadow: 'inset 6px 6px 10px rgba(176, 179, 185, 0.383466)',
                borderRadius: 'inputs',
                h: '28px',
                fontSize: 'lg'
            }
        }
    }
};
