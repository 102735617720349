import React from 'react';
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverProps, PopoverTrigger } from '@chakra-ui/popover';
import { popoverContainerStylingProps } from '../const';
import { BoxProps } from '@chakra-ui/layout';
import { Portal } from '@chakra-ui/react';

interface DesktopInfoPopOverProps {
    children: React.ReactNode;
    body: React.ReactNode;
    placement?: PopoverProps['placement'];
    trigger?: PopoverProps['trigger'];
    isOpen?: boolean;
    maxWidth: BoxProps['maxWidth'];
    fullWidth?: boolean;
}

export const DesktopInfoPopover = ({
    body,
    placement,
    trigger,
    children,
    isOpen,
    maxWidth,
    fullWidth
}: DesktopInfoPopOverProps) => {
    const isPopoverOpen = isOpen ? true : undefined;
    if (fullWidth) {
        popoverContainerStylingProps.width = '100%';
    }

    return (
        <Popover
            preventOverflow={false}
            gutter={20}
            isOpen={isPopoverOpen}
            placement={placement}
            trigger={trigger}
            isLazy
            arrowSize={29}
            arrowShadowColor="transparent"
        >
            <PopoverTrigger>{children}</PopoverTrigger>
            <Portal>
                <PopoverContent {...popoverContainerStylingProps} maxWidth={maxWidth} _focus={{ outline: 'none' }}>
                    <PopoverArrow bg="greyScheme.200" borderRadius="popover.arrow" boxShadow="none" border="none" />
                    <PopoverBody whiteSpace="pre-wrap" p={0}>
                        {body}
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
