import { Flex, Text, Link } from '@chakra-ui/layout';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CellProps } from '../../types';
import { makeSelectPendingIdInfo } from '../../../../../slices/pendingOrders/selectors';

export const LinkId = ({ orderId }: CellProps) => {
    const selectIdInfo = useMemo(() => makeSelectPendingIdInfo(orderId), [orderId]);

    const { human_id } = useSelector(selectIdInfo);

    return (
        <Flex ml={4} justify="space-between">
            <Text>
                <Link href={`${process.env.REACT_APP_HOST_URL}/admin/orders/${orderId}`} target="_blank">
                    {human_id}
                </Link>
            </Text>
        </Flex>
    );
};
