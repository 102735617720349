import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { LoginPage } from 'containers/LoginPage';
import { Redirect } from 'react-router-dom';
import { LogoutPage } from '../LogoutPage';
import { ApiConfig } from '../../api/ApiConfig';
import { PrivateRoute } from '../../components/PrivateRoute';
import { TopBar } from '../../components/TopBar';
import { VStack } from '@chakra-ui/layout';
import { OrderDetails } from '../OrderDetails';
import { Dashboard } from 'containers/Dashboards/Dashboard';
import { useDispatch } from 'react-redux';
import { unwatchDeviceLiveStatus, watchDeviceLiveStatus } from '../DeviceLiveStatus/slice';
import { watchLiveOrders, unwatchLiveOrders } from '../../slices/orders/slice';
import { ScrollToTop } from '../../utils';

export const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(watchDeviceLiveStatus());
        dispatch(watchLiveOrders());

        return () => {
            dispatch(unwatchDeviceLiveStatus());
            dispatch(unwatchLiveOrders());
        };
    }, []);

    return (
        <ApiConfig>
            <ScrollToTop />
            <Switch>
                <Route path="/login" component={LoginPage} />
                <PrivateRoute path="/logout" component={LogoutPage} />

                <Route path="/">
                    {/* FIXME handle 404 routes properly */}
                    <Switch>
                        <Route path="/" exact>
                            <Redirect to="/dashboard" />
                        </Route>
                    </Switch>

                    <VStack w="full" minH="full">
                        <Switch>
                            <PrivateRoute path="/dashboard">
                                <TopBar />
                                <Dashboard />
                            </PrivateRoute>

                            <PrivateRoute path="/order/:id">
                                <TopBar showGoBack />
                                {/* TODO 404 check if id Exists */}
                                <OrderDetails />
                            </PrivateRoute>
                        </Switch>
                    </VStack>
                </Route>
            </Switch>
        </ApiConfig>
    );
};
