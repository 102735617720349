import React from 'react';
import { MobileDashboard } from '../MobileDashboard';
import { DesktopDashboard } from '../DesktopDashboard';
import { useIsMobileView } from '../../../hooks';

export const Dashboard = () => {
    const isMobileView = useIsMobileView();

    if (isMobileView) {
        return <MobileDashboard />;
    }

    return <DesktopDashboard />;
};
