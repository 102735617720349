import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';

// https://github.com/reduxjs/redux-toolkit/blob/22e407454faccaef86d9c869ad9bb42f2473b5d9/packages/toolkit/src/query/tests/errorHandling.test.tsx
export const axiosBaseQueryFactory =
    (
        axiosInstance: AxiosInstance
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
        },
        unknown,
        unknown,
        unknown,
        { response: AxiosResponse; request: AxiosRequestConfig }
    > =>
    async ({ url, method, data }) => {
        const config = { url, method, data };

        try {
            const result = await axiosInstance(config);
            return {
                data: result.data,
                meta: { request: config, response: result }
            };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data
                },
                meta: { request: config, response: err.response as AxiosResponse }
            };
        }
    };
