export const Tabs = {
    baseStyle: {
        tab: {
            lineHeight: 0,
            _selected: {
                borderBottomWidth: '2px',
                borderBottomColor: 'yellow',
                marginBottom: '-2px'
            },
            _focus: {
                boxShadow: 'none'
            }
        }
    },
    defaultProps: {
        variant: 'unstyled'
    }
};
