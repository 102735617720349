import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PhoneIcon = createIcon({
    displayName: 'PhoneIcon',
    viewBox: '0 0 19 19',
    path: (
        <path
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.37C0 12.4374 6.5626 19 14.63 19C17.0392 19 19 17.0401 19 14.63C19 14.3849 18.9791 14.1417 18.9392 13.9071C18.9002 13.6686 18.8414 13.4368 18.7635 13.2126C18.6571 12.9038 18.3977 12.672 18.0795 12.5989L12.4127 11.2984C12.103 11.2299 11.7743 11.3164 11.5425 11.5387C11.4076 11.6679 11.4038 11.6717 10.7863 12.8506C8.7628 11.9291 7.10505 10.2771 6.15125 8.2118C7.32925 7.59525 7.3321 7.5924 7.4613 7.4575C7.6836 7.2257 7.77385 6.8989 7.70165 6.5873L6.4011 0.9215C6.32795 0.6023 6.0952 0.34295 5.78645 0.2356C5.56035 0.1577 5.32855 0.0988 5.0977 0.06175C4.86115 0.0209 4.617 0 4.37 0C1.95985 0 0 1.95985 0 4.37ZM2 4.4375C2 3.09312 3.09312 2 4.4375 2C4.53969 2 4.64094 2.00656 4.745 2.01969L5.73875 6.35187C5.57656 6.43625 5.39937 6.52906 5.23531 6.61531C4.42719 7.03719 3.78969 7.37094 4.12438 8.10687C5.22594 11.2437 7.75625 13.775 10.8556 14.8625C11.644 15.1799 11.9536 14.5893 12.3803 13.7752L12.3819 13.7722C12.4387 13.6622 12.4992 13.5469 12.5586 13.4339C12.5893 13.3754 12.6197 13.3175 12.6491 13.2612L16.9803 14.255C16.9934 14.3562 17 14.4584 17 14.5625C17 15.9069 15.9059 17 14.5625 17C7.63531 17 2 11.3638 2 4.4375Z"
            fill="currentColor"
        />
    )
});
