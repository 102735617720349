import React from 'react';
import { HStack } from '@chakra-ui/react';
import { StackProps } from '@chakra-ui/layout';
import { Separator } from '../../../../components/Separator';

interface SeparatorStackProps {
    children: React.ReactNode;
}

export const SeparatorStack = ({ children, ...props }: SeparatorStackProps & StackProps) => {
    return (
        <>
            <Separator />
            <HStack {...props}>{children}</HStack>
        </>
    );
};
