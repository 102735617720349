import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';
import { InfoPopover } from '../../../../../components/InfoPopover';
import { makeSelectOrderType } from '../../../../../slices/orders/selectors';
import { CellProps } from '../../types';
import { Icon } from '../../../../../components/Icon';
import { ORDER_DESCRIPTION } from './consts';
import { OrderKind } from 'types';
import { isNil } from 'ramda';
import { TextProps } from '@chakra-ui/layout';

export interface OrderTypeIconProps {
    orderType: OrderKind;
    size?: TextProps['fontSize'];
}

export interface OrderBadgeProps {
    children: ReactNode;
    size?: TextProps['fontSize'];
}

export const OrderBadge = ({ children, size }: OrderBadgeProps) => (
    <Text fontWeight="bold" fontSize={size}>
        {children}
    </Text>
);

export const OrderTypeIcon = ({ orderType, size }: OrderTypeIconProps) => {
    switch (orderType) {
        case OrderKind.preSet:
            return <OrderBadge size={size}>PreSet</OrderBadge>;
        case OrderKind.remote:
            return <Icon size={size} name="RestaurantIcon" />;
        case OrderKind.butler24:
            return <OrderBadge size={size}>Butler24</OrderBadge>;
        case OrderKind.hotel:
            return <Icon size={size} name="HotelIcon" />;
        case OrderKind.corporate:
            return <OrderBadge size={size}>Corporate</OrderBadge>;
        case OrderKind.vivaCity:
            return <OrderBadge size={size}>VivaCity</OrderBadge>;
        default:
            console.assert(orderType === OrderKind.feast);
            return <></>;
    }
};

export const OrderType = ({ orderId }: CellProps) => {
    const selectOrderType = useMemo(() => makeSelectOrderType(orderId), [orderId]);
    const { order_type, may_be_fraud, for_collection, business_id, by_admin } = useSelector(selectOrderType);

    const orderTypeDescription = ORDER_DESCRIPTION[order_type];
    const color = may_be_fraud ? 'red' : 'currentColor';

    return (
        <HStack color={color} align="center" justify="center">
            <InfoPopover body={orderTypeDescription}>
                <Box cursor="pointer">
                    <OrderTypeIcon orderType={order_type} />
                </Box>
            </InfoPopover>

            {for_collection && (
                <InfoPopover body="Order placed for collection (no delivery necessary)">
                    <Box cursor="pointer">
                        <Icon name="CollectIcon" />
                    </Box>
                </InfoPopover>
            )}

            {!isNil(business_id) && (
                <InfoPopover body="Order placed for collection (no delivery necessary)">
                    <Box cursor="pointer">
                        <OrderBadge>VIP</OrderBadge>
                    </Box>
                </InfoPopover>
            )}

            {by_admin && (
                <InfoPopover body="Order placed by Feast administrator">
                    <Box cursor="pointer">
                        <OrderBadge>Admin</OrderBadge>
                    </Box>
                </InfoPopover>
            )}
        </HStack>
    );
};
