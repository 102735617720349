import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { Icon } from '../Icon';
import { isEmpty } from 'ramda';

export enum CallButtonVariants {
    default = 'default',
    requested = 'requested'
}

export interface CallButtonProps {
    phone?: string;
    variant?: CallButtonVariants;
    onClick?: () => void;
}

export const CallButton = ({
    phone = '',
    variant = CallButtonVariants.default,
    onClick = () => {}
}: CallButtonProps) => {
    return (
        <IconButton
            onClick={() => {
                if (!isEmpty(phone)) {
                    window.location.href = `tel:${phone}'`;
                }
                onClick();
            }}
            icon={
                <Icon name={variant === CallButtonVariants.default ? 'PhoneIcon' : 'RequestedSupportIcon'} size={5} />
            }
            aria-label="Call"
        />
    );
};
