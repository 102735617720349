import React, { useMemo } from 'react';
import { Text } from '@chakra-ui/layout';
import { Flex, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { InfoPopover } from '../../../../../components/InfoPopover';
import { CellProps } from '../../types';
import { makeSelectPriceComponents } from '../../../../../slices/orders/selectors';
import { Icon } from '../../../../../components/Icon';
import { formatPrice } from 'utils';

export const Price = ({ orderId }: CellProps) => {
    const selectPriceComponents = useMemo(() => makeSelectPriceComponents(orderId), [orderId]);
    const { priceComponents, paymentMethod, paid, refunds, totalRefundAmount, priceCents } =
        useSelector(selectPriceComponents);

    if (!priceComponents) {
        return null;
    }

    const {
        original_price,
        delivery_fee,
        hotel_service_fee,
        grand_total_price,
        admin_fee,
        discount_value,
        feast_tip,
        driver_tip
    } = priceComponents;

    const isDiscounted = (discount_value ?? 0) !== 0;
    const hasRefunds = !isNil(refunds);

    const prices = (
        <>
            <Text variant="infoPopover">
                Food: <b>{formatPrice(original_price)}</b>
            </Text>
            <Text variant="infoPopover">
                Delivery fee: <b>{formatPrice(delivery_fee)}</b>
            </Text>
            {!isNil(hotel_service_fee) && (
                <Text variant="infoPopover">
                    Hotel commission: <b>{formatPrice(hotel_service_fee)}</b>
                </Text>
            )}
            {!isNil(admin_fee) && (
                <Text variant="infoPopover">
                    Administration fee: <b>{formatPrice(admin_fee)}</b>
                </Text>
            )}
            {!isNil(grand_total_price) && (
                <Text variant="infoPopover">
                    Total: <b>{formatPrice(grand_total_price)}</b>
                </Text>
            )}
        </>
    );

    const paidInformation = paid && (
        <>
            {isDiscounted && (
                <Text variant="infoPopover">
                    Discount <b>{formatPrice(discount_value)}</b>
                </Text>
            )}
            <Text variant="infoPopover">
                Paid <b>{formatPrice(priceCents)}</b>
            </Text>
        </>
    );

    const refundInformation = !isNil(refunds) && (
        <Text variant="infoPopover">
            Customer was refunded: <b>{formatPrice(totalRefundAmount)}</b>
        </Text>
    );

    const tipInformation = (feast_tip ?? 0 + driver_tip ?? 0) > 0 && (
        <>
            {feast_tip > 0 && (
                <Text variant="infoPopover">
                    Feast tip: <b>{formatPrice(feast_tip)}</b>
                </Text>
            )}
            {driver_tip > 0 && (
                <Text variant="infoPopover">
                    Driver tip: <b>{formatPrice(driver_tip)}</b>
                </Text>
            )}
        </>
    );

    return (
        <InfoPopover body={[prices, paidInformation, paymentMethod, refundInformation, tipInformation]}>
            <Flex justify="start" position="relative" cursor="pointer">
                {!isNil(refunds) && (
                    <Box left={-1} top={-1} position="absolute">
                        <Icon name="RefundIcon" />
                    </Box>
                )}

                <Text fontWeight="bold" mr={4} ml={2} color={getPriceColor(hasRefunds, isDiscounted)}>
                    {formatPrice(priceComponents.grand_total_price)}
                </Text>
            </Flex>
        </InfoPopover>
    );
};

const getPriceColor = (hasRefunds: boolean, isDiscounted: boolean) => {
    if (hasRefunds) {
        return 'red.300';
    }
    if (isDiscounted) {
        return 'blueScheme.200';
    }
    return 'currentColor';
};
