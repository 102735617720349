import { Box } from '@chakra-ui/layout';
import React from 'react';

export const FeastOval = () => (
    <Box position="absolute" transform="translate3d(-26%, 11%, 0)">
        <svg width="966" height="758" viewBox="0 0 966 758" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.36203" cx="439" cy="527" r="527" fill="url(#paint0_radial_0_1765)" />
            <defs>
                <radialGradient
                    id="paint0_radial_0_1765"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(807.444 152.49) rotate(124.11) scale(543.999)"
                >
                    <stop stopColor="#254994" />
                    <stop offset="1" stopColor="#254994" stopOpacity="0.01" />
                </radialGradient>
            </defs>
        </svg>
    </Box>
);
