import { createSelector } from '@reduxjs/toolkit';
import { isNil, isEmpty } from 'ramda';
import { RootState } from 'store';
import { OrderKind } from 'types';
import { getDeliveryStatus, stuartCancelationStatusExists } from './api';
import { OrdersState } from './types';

export const ordersSelector = (state: RootState): OrdersState => state.orders;

export const selectOrdersIds = createSelector(ordersSelector, (state) => {
    if (state.isFilterView) {
        return state.filteredOrdersIds;
    } else {
        return state.liveOrdersIds;
    }
});

export const selectOrdersById = createSelector(ordersSelector, (state) => {
    if (state.isFilterView) {
        return state.filteredOrdersById;
    } else {
        return state.liveOrdersById;
    }
});

export const selectAreLiveOrdersLoading = createSelector(ordersSelector, (state) => state.areLiveOrdersLoading);

export const selectIsFilterView = createSelector(ordersSelector, (state) => !isEmpty(state.filters));
export const selectFilters = createSelector(ordersSelector, (state) => new URLSearchParams(state.filters).toString());

export const makeSelectLiveOrder = (orderId: string) =>
    createSelector(selectOrdersById, (orders) => orders[orderId] ?? {});

export const makeSelectLiveIdInfo = (orderId: string) =>
    createSelector(
        makeSelectLiveOrder(orderId),
        ({
            human_id,
            elapsed_time,
            belongs_to_order_human_id,
            belongs_to_order,
            extended_by_order_human_id,
            extended_by_order,
            logs = []
        }) => ({
            human_id,
            elapsed_time,
            belongs_to_order,
            belongs_to_order_human_id,
            extended_by_order,
            extended_by_order_human_id,
            logs
        })
    );

export const makeSelectHotel = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => order.hotel);

export const makeSelectRestaurant = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => {
        const { restaurant, address, pre_order_delivery_hour } = order;
        const restaurantHasNoDevice = restaurant.auto_confirm === true;

        return {
            restaurantId: restaurant.id,
            name: restaurant.name,
            isDeviceAvailable: !restaurantHasNoDevice,
            noteForRestaurant: address.note_for_restaurant,
            preOrderDeliveryHour: pre_order_delivery_hour
        };
    });

export const makeSelectPriceComponents = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => ({
        priceComponents: order.price_components,
        paymentMethod: order.payment_method,
        priceCents: order.price_cents,
        paid: order.paid,
        totalRefundAmount: order.total_refund_amount,
        refunds: order.refunds
    }));

export const makeSelectCreationDate = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => order.created_at);

export const makeSelectDeliveryStatus = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => getDeliveryStatus(order));

export const makeSelectAddressInfo = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => order.address);

export const makeSelectDeliveryInfo = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), makeSelectLiveOrder(orderId), (order, status) => {
        const {
            task_id,
            estimated_pickup_time,
            estimated_delivery_time,
            pickup_started,
            pickup_completed,
            delivery_started,
            delivered,
            driver,
            delivery_time,
            address,
            tracking_url,
            stuart
        } = order;

        return {
            stuartCancellation: stuartCancelationStatusExists(stuart),
            status,
            task_id,
            estimated_pickup_time,
            estimated_delivery_time,
            pickup_started,
            pickup_completed,
            delivery_started,
            delivered,
            delivery_time,
            tracking_url,
            driver: isNil(driver)
                ? null
                : {
                      name: driver?.name ?? '',
                      phone: driver?.phone ?? ''
                  },
            note_for_driver: address?.note_for_driver ?? ''
        };
    });

export const makeSelectCustomer = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => {
        const { user } = order;
        return {
            ...(user ?? {}),
            note: user.note_by_admin ?? ''
        };
    });

export const makeSelectOrderType = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => {
        const { order_type, may_be_fraud, for_collection, by_admin, business_id } = order;
        return { order_type: order_type as OrderKind, may_be_fraud, for_collection, by_admin, business_id };
    });

export const makeSelectOrderStatus = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => {
        const { confirmed, cancelled, call_restaurant, restaurant, extended_by_order } = order;
        const { primary_number } = restaurant ?? {};
        return { confirmed, cancelled, call_restaurant: call_restaurant ?? false, primary_number, extended_by_order };
    });

export const makeSelectIsRowHighlighted = (orderId: string) =>
    createSelector(makeSelectLiveOrder(orderId), (order) => order.price_components.grand_total_price > 8000);
