import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const MessageIcon = createIcon({
    displayName: 'MessageIcon',
    viewBox: '0 0 20 16',
    path: (
        <path
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0H17C18.654 0 20 1.346 20 3V13C20 14.654 18.654 16 17 16H3C1.346 16 0 14.654 0 13V3C0 1.346 1.346 0 3 0ZM16.333 2L10 6.75L3.667 2H16.333ZM3 14C2.449 14 2 13.552 2 13V3.25L9.4 8.8C9.578 8.934 9.789 9 10 9C10.211 9 10.422 8.934 10.6 8.8L18 3.25V13C18 13.552 17.551 14 17 14H3Z"
            fill="currentColor"
        />
    )
});
