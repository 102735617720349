import React from 'react';
import { VStack } from '@chakra-ui/react';
import { EmailButton } from './EmailButton';
import {
    useSendCustomerUnreachableEmailMutation,
    useSendDelayEmailMutation,
    useSendFailEmailMutation,
    useSendFraudEmailMutation
} from '../../../../api/service';

interface EmailActionsProps {
    orderId: string;
    userId: string;
}

export const EmailActions = ({ orderId, userId }: EmailActionsProps) => {
    const [sendCustomerUnreachableEmail] = useSendCustomerUnreachableEmailMutation();
    const [sendDelayEmail] = useSendDelayEmailMutation();
    const [sendFraudEmail] = useSendFraudEmailMutation();
    const [sendFailEmail] = useSendFailEmailMutation();
    return (
        <VStack w="full" pt={2} pb={2} pl={2} spacing={4}>
            <EmailButton
                info={
                    <>
                        Send email to this client saying that <strong>the delivery may take longer than usual.</strong>
                    </>
                }
                label="e-mail: Slight delay"
                onClick={() => sendDelayEmail({ orderId, userId })}
            />
            <EmailButton
                info={
                    <>
                        Send email to this client saying that{' '}
                        <strong>their most recent transaction has been blocked.</strong>
                    </>
                }
                onClick={() => sendFraudEmail({ orderId, userId })}
                label="e-mail: Fraud"
            />
            <EmailButton
                info={
                    <>
                        Send email to this client saying that{' '}
                        <strong>their food has been left outside the property.</strong>
                    </>
                }
                onClick={() => sendFailEmail({ orderId, userId })}
                label="e-mail: Failed to contact"
            />

            <EmailButton
                info={
                    <>
                        Send email to this address saying <strong>we&apos;re outside but cannot get through</strong> to
                        them on phone.
                    </>
                }
                onClick={() => sendCustomerUnreachableEmail({ orderId, userId })}
                label="e-mail: Cannot get through"
            />
        </VStack>
    );
};
