import React from 'react';
import { MobileTabs } from '../../../components/Tabs';
import { CardView } from './CardView';
import { PendingView } from '../DesktopDashboard/PendingView';

export const MobileDashboard = () => {
    return (
        <MobileTabs
            tabs={[
                {
                    label: 'Card view',
                    content: <CardView />
                },
                {
                    label: 'Pending payment',
                    content: <PendingView />
                }
            ]}
        />
    );
};
