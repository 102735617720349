import React from 'react';
import { DesktopTabs } from '../../../components/Tabs';
import { PendingView } from './PendingView';
import { TableView } from './TableView';

export const DesktopDashboard = () => (
    <DesktopTabs
        tabs={[
            {
                label: 'Table view',
                content: <TableView />
            },
            {
                label: 'Pending payment',
                content: <PendingView />
            }
        ]}
    />
);
