import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RestaurantDeviceStatus } from './types';

export interface DeviceLiveStatus {
    byRestaurantId: Record<string, RestaurantDeviceStatus>;
}

const initialState: DeviceLiveStatus = {
    byRestaurantId: {}
};
const sliceName = 'deviceLiveStatus';

const deviceLiveStatusSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        statusChanged: (state, action: PayloadAction<{ restaurantId: string; status: RestaurantDeviceStatus }>) => {
            const { restaurantId, status } = action.payload;
            state.byRestaurantId[restaurantId] = status;
        },
        initializeStatuses: (state, action) => {
            state.byRestaurantId = action.payload ?? {};
        }
    }
});

export const { statusChanged, initializeStatuses } = deviceLiveStatusSlice.actions;

export const watchDeviceLiveStatus = createAction(`${sliceName}/watchDeviceLiveStatus`);
export const unwatchDeviceLiveStatus = createAction(`${sliceName}/unwatchDeviceLiveStatus`);

export const deviceLiveStatusReducer = deviceLiveStatusSlice.reducer;
