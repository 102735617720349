import React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import { VStack, HStack, Button, IconButton } from '@chakra-ui/react';
import { Icon } from '../Icon';
import { InfoPopover } from '../InfoPopover';
import { TextMenuOption } from './components/TextMenuOption';

export interface CustomerMenuBodyProps {
    customer: {
        email: string;
        phone: string;
    };
    items: {
        label: string;
        info?: React.ReactNode;
        onClick?: () => void;
    }[];
}

export const CustomerMenuBody = ({ customer, items }: CustomerMenuBodyProps) => {
    const { email, phone } = customer;

    return (
        <VStack w="full">
            <HStack m={0} w="full">
                <InfoPopover body="Click to send an email to this address." placement="left">
                    <Button variant="menu" isFullWidth>
                        <a href={`mailto:${email}`}>{email}</a>
                    </Button>
                </InfoPopover>

                <InfoPopover body="Copy this email to clipboard." placement="right">
                    <IconButton
                        onClick={() => copyToClipboard(email)}
                        variant="menu"
                        justifyContent="center"
                        icon={<Icon name="MessageWithSquareIcon" />}
                        aria-label=""
                    />
                </InfoPopover>
            </HStack>

            <HStack m={0} w="full">
                <TextMenuOption>{phone}</TextMenuOption>

                <InfoPopover body="Copy this phone number to clipboard." placement="right">
                    <IconButton
                        onClick={() => copyToClipboard(phone)}
                        variant="menu"
                        justifyContent="center"
                        icon={<Icon name="PhoneWithSquareIcon" />}
                        aria-label=""
                    />
                </InfoPopover>
            </HStack>

            {items.map(({ label, info = '', onClick }) => (
                <InfoPopover key={label} body={info} placement="left">
                    <Button variant="menu" isFullWidth onClick={onClick}>
                        {label}
                    </Button>
                </InfoPopover>
            ))}
        </VStack>
    );
};

CustomerMenuBody.defaultProps = {
    items: []
};
