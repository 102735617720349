import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PaymentIcon = createIcon({
    displayName: 'PaymentIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.00006 6H17.0001C18.6541 6 20.0001 7.346 20.0001 9V17C20.0001 18.654 18.6541 20 17.0001 20H3.00006C1.34606 20 6.10352e-05 18.654 6.10352e-05 17V9C6.10352e-05 7.346 1.34606 6 3.00006 6ZM2.00006 9C2.00006 8.449 2.44806 8 3.00006 8H17.0001C17.5521 8 18.0001 8.449 18.0001 9V10H2.00006V9ZM17.0001 18C17.5521 18 18.0001 17.551 18.0001 17V12H2.00006V17C2.00006 17.551 2.44806 18 3.00006 18H17.0001ZM5.00006 16H9.00006C9.55006 16 10.0001 15.55 10.0001 15C10.0001 14.45 9.55006 14 9.00006 14H5.00006C4.45006 14 4.00006 14.45 4.00006 15C4.00006 15.55 4.45006 16 5.00006 16ZM13.0001 16H15.0001C15.5501 16 16.0001 15.55 16.0001 15C16.0001 14.45 15.5501 14 15.0001 14H13.0001C12.4501 14 12.0001 14.45 12.0001 15C12.0001 15.55 12.4501 16 13.0001 16Z"
                fill="currentColor"
            />
            <circle cx="10.0001" cy="5" r="4" fill="white" stroke="currentColor" strokeWidth="2" />
        </>
    )
});
