import { pick } from 'ramda';
import { orderConfirmationChanged } from 'slices/orders/slice';
import { injectEndpoints } from '../../api/service';
import { createErrorToast } from '../../utils';
import { createSuccessToast } from '../../utils/createSuccessToast';

export const orderConfirmationApi = injectEndpoints({
    endpoints: (builder) => ({
        putConfirmationStatus: builder.mutation<any, any>({
            query: ({ orderId, confirmed }) => ({
                url: `/orders/${orderId}`,
                method: 'put',
                data: { order: { id: orderId, confirmed: confirmed } }
            }),

            transformResponse: pick(['id', 'confirmed']),

            onQueryStarted: async ({ orderId, confirmed }, { dispatch, queryFulfilled }) => {
                dispatch(orderConfirmationChanged({ orderId, confirmed }));
                createSuccessToast('Order confirmation status change request sent');

                try {
                    await queryFulfilled;
                } catch (err: any) {
                    dispatch(orderConfirmationChanged({ orderId, confirmed: !confirmed }));
                    createErrorToast('Order confirmation status change request failed', err.error.status);
                }
            }
        })
    })
});

export const { usePutConfirmationStatusMutation } = orderConfirmationApi;
