import React from 'react';
import { Link } from 'react-router-dom';
import { Box, GridItem, HStack, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import { isNil } from 'ramda';
import { Icon } from '../../components/Icon';
import { ElapsedTime } from '../../components/ElapsedTime';
import { formatOrderCardDate, formatOrderCardTime, formatPrice } from '../../utils';
import { OrderTypeIcon } from '../Dashboards/DesktopDashboard/OrderRow/cells/OrderType';

interface CardHeaderProps {
    variant: HeaderVariants;
    order: any;
}

export enum HeaderVariants {
    default = 'default',
    details = 'details'
}

export const CardHeader = ({ variant, order }: CardHeaderProps) => {
    const {
        human_id,
        price_cents,
        delivery_time,
        created_at,
        pre_order_delivery_hour,
        belongs_to_order_human_id,
        belongs_to_order,
        may_be_fraud,
        order_type,
        extended_by_order_human_id,
        extended_by_order
    } = order;
    const pounds: string = formatPrice(price_cents);
    const createdAtDay: string = formatOrderCardDate(created_at);
    const createdAtHour: string = formatOrderCardTime(created_at);

    const content = (
        <HStack w="full" pl={3} pr={6} pt={3} pb={1} justifyContent="space-between" position="relative">
            <VStack alignItems="flex-start" spacing={0}>
                <Stack spacing={2} w="full" direction="row" justifyContent="flex-start" alignItems="center">
                    <Text fontSize="2xl" fontWeight="bold">
                        {!isNil(human_id) && human_id}
                    </Text>
                    {!isNil(belongs_to_order_human_id) && !isNil(belongs_to_order) && (
                        <Link to={`/order/${belongs_to_order}`}>
                            <Text fontSize="sm">{`+${belongs_to_order_human_id}`}</Text>
                        </Link>
                    )}
                    {!isNil(extended_by_order_human_id) && !isNil(extended_by_order) && (
                        <Link to={`/order/${extended_by_order}`}>
                            <Text fontSize="sm">{`+${extended_by_order_human_id}`}</Text>
                        </Link>
                    )}
                </Stack>
                <Stack spacing={2} w="full" direction="row" justifyContent="flex-start" alignItems="center">
                    <Text color="fonts.secondary" fontSize="sm">
                        {!isNil(created_at) && createdAtDay}
                    </Text>
                    <Text fontWeight="bold" color="fonts.secondary" fontSize="sm">
                        {!isNil(created_at) && createdAtHour}
                    </Text>
                </Stack>

                {!isNil(pre_order_delivery_hour) && (
                    <Text fontSize="md" fontWeight="bold">
                        {pre_order_delivery_hour}
                    </Text>
                )}
            </VStack>
            <Stack w="full" direction="row" justifyContent="space-between">
                <SimpleGrid w="full" columns={3} alignItems="center">
                    <GridItem colSpan={2} display="flex" justifyContent="center" zIndex={1}>
                        <VStack alignItems="flex-start" spacing={0}>
                            <ElapsedTime fontSize="2xl">{delivery_time}</ElapsedTime>
                            {!isNil(price_cents) && <Text fontWeight="bold">{pounds}</Text>}
                        </VStack>
                    </GridItem>
                    <GridItem colSpan={1} justifyContent="flex-end" display="flex">
                        <Box
                            pb={0}
                            mb={0}
                            position="absolute"
                            bottom="-25%"
                            top="0.1%"
                            right="12%"
                            color={may_be_fraud ? 'red' : 'grey3'}
                        >
                            <OrderTypeIcon orderType={order_type} size={24} />
                        </Box>
                        {variant === HeaderVariants.default && <Icon name="RightArrowIcon" size={3} />}
                    </GridItem>
                </SimpleGrid>
            </Stack>
        </HStack>
    );

    return variant === HeaderVariants.default ? <Link to={`/order/${order.id}`}>{content}</Link> : content;
};
