import { Link, Text } from '@chakra-ui/layout';
import { Flex, Spacer } from '@chakra-ui/react';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectRestaurant } from 'slices/orders/selectors';
import { CellProps } from '../../types';
import { InfoPopover } from '../../../../../components/InfoPopover';
import { Icon } from '../../../../../components/Icon';
import { RestaurantMenuBody } from '../../../../../components/MenuPopover/RestaurantMenuBody';
import { MenuPopover } from '../../../../../components/MenuPopover';
import { DeviceLiveStatus } from '../../../../DeviceLiveStatus';

export const Restaurant = ({ orderId }: CellProps) => {
    const selectRestaurant = useMemo(() => makeSelectRestaurant(orderId), [orderId]);

    const { restaurantId, name, isDeviceAvailable, noteForRestaurant, preOrderDeliveryHour } =
        useSelector(selectRestaurant);

    const restaurantMenuBody = (
        <RestaurantMenuBody restaurantId={restaurantId} orderId={orderId} isDeviceAvailable={isDeviceAvailable} />
    );

    return (
        <Flex align="center" flexDirection="row" gridGap={2}>
            <DeviceLiveStatus restaurantId={restaurantId} />

            <MenuPopover body={restaurantMenuBody}>
                <Link href={`${process.env.REACT_APP_HOST_URL}/admin/restaurants/${restaurantId}`} target="_blank">
                    {name}
                </Link>
            </MenuPopover>
            <Spacer />

            {noteForRestaurant && (
                <InfoPopover body={noteForRestaurant}>
                    <Flex ml="auto" cursor="pointer">
                        <Icon name="NotesIcon" size={5} />
                    </Flex>
                </InfoPopover>
            )}

            {preOrderDeliveryHour && (
                <Text ml="auto" fontWeight="bold">
                    {preOrderDeliveryHour}
                </Text>
            )}
        </Flex>
    );
};
