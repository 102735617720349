export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date
        .toLocaleDateString('en-GB', {
            year: '2-digit',
            month: 'short',
            day: 'numeric'
        })
        .replaceAll(' ', '/');
};

export const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-GB', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
    });
};
