export enum DeliveryStatus {
    handledByRestaurant = 'handledByRestaurant',
    processPending = 'processPending',
    pickupInProgress = 'pickupInProgress',
    pickupDone = 'pickupDone',
    completed = 'completed',
    cancelled = 'cancelled'
}

export enum OrderKind {
    preSet = 'pre_set',
    feast = 'feast',
    remote = 'remote',
    butler24 = 'butler24',
    hotel = 'hotel',
    corporate = 'corporate',
    vivaCity = 'viva_city'
}
