import React from 'react';
import { OrderCard } from '../../OrderCard';
import { VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectAreLiveOrdersLoading, selectOrdersIds } from '../../../slices/orders/selectors';

export const CardView = () => {
    const allOrderIds = useSelector(selectOrdersIds);
    const areLiveOrdersLoading = useSelector(selectAreLiveOrdersLoading);

    if (areLiveOrdersLoading) {
        return <>Loading...</>;
    }

    return (
        <VStack px={2} gridGap={5}>
            {allOrderIds.map((orderId: string) => {
                return <OrderCard key={orderId} orderId={orderId} />;
            })}
        </VStack>
    );
};
