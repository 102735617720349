import { Table } from './components/table';
import { extendTheme } from '@chakra-ui/react';
import { colors } from './foundations/colors';
import { fonts, fontSizes, fontWeights } from './foundations/fonts';
import { Text } from './components/text';
import { Button } from './components/button';
import { Divider } from './components/divider';
import { Input } from './components/input';
import { Switch } from './components/switch';
import { Tabs } from './components/tabs';
import { Select } from './components/select';
import { Alert } from './components/alert';

import { shadows } from './foundations/shadows';
import { radii } from './foundations/radii';
import { sizes } from './foundations/sizes';
import { space } from './foundations/spacing';

const overrides = {
    colors,
    fonts,
    fontSizes,
    fontWeights,
    shadows,
    radii,
    space,
    sizes,
    borderWidths: {
        card: {
            deliveryStatus: '6px'
        }
    },
    components: {
        Button,
        Divider,
        Input,
        Switch,
        Table,
        Tabs,
        Text,
        Select,
        Alert
    }
};

export const theme = extendTheme(overrides);
