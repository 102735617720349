import axios from 'axios';
import { getTokens } from './auth';
import { API_CONFIG } from './const';

export const api = axios.create(API_CONFIG);

export const configureApi = (accessToken: string | undefined | null) => {
    setAuthorizationHeader(accessToken);
};

export const setAuthorizationHeader = (accessToken: string | undefined | null) => {
    api.defaults.headers.common.Authorization = accessToken ?? '';
};

export const resetApi = () => {
    setAuthorizationHeader('');
};

export const hydrateAccessToken = () => {
    const { accessToken, hasTokens } = getTokens();

    if (hasTokens) {
        configureApi(accessToken);
    }
};
