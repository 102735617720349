import { theme as base } from '@chakra-ui/react';

export const fonts = {
    body: `Calibri, ${base.fonts?.body}`,
    heading: `Calibri, ${base.fonts?.body}`,
    monospace: `'Roboto Mono', monospace;`
};

export const fontSizes = {
    xs: '8px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '22px'
};

export const fontWeights = {
    normal: 400,
    bold: 700
};
