import React from 'react';
import { useSelector } from 'react-redux';
import { PENDING_ORDER_TABLE_COLUMNS_CONFIG } from './const';
import { OrderTable } from '../../../components/OrderTable/OrderTable';
import PendingRow from './PendingRow/PendingRow';
import { ORDERS_POLLING_INTERVAL_MS } from '../../../slices/consts';
import { useGetPendingOrdersQuery } from '../../../slices/pendingOrders/api';
import { selectPendingOrderIds } from '../../../slices/pendingOrders/selectors';

export const PendingView = () => {
    const { isLoading } = useGetPendingOrdersQuery(undefined, { pollingInterval: ORDERS_POLLING_INTERVAL_MS });

    const pendingOrdersIds = useSelector(selectPendingOrderIds);

    if (isLoading) {
        return <>Loading...</>;
    }

    return (
        <>
            <OrderTable columns={PENDING_ORDER_TABLE_COLUMNS_CONFIG}>
                {pendingOrdersIds.map((orderId: any) => {
                    return <PendingRow key={orderId} orderId={orderId} />;
                })}
            </OrderTable>
        </>
    );
};
