import { configureStore } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';
import { AnyAction } from 'redux';
import { feastApi } from './api/service';
import { ordersReducer } from './slices/orders/slice';
import { deviceLiveStatusReducer } from './containers/DeviceLiveStatus/slice';
import { deviceLiveStatusEpics } from './containers/DeviceLiveStatus/epics';
import { pendingOrdersReducer } from './slices/pendingOrders/slice';
import { liveOrdersEpic } from './slices/orders/epics';

export type BaseEpic = Epic<AnyAction, AnyAction, RootState, never>;

const epicMiddleware = createEpicMiddleware();

const rootEpic = combineEpics(deviceLiveStatusEpics, liveOrdersEpic);

export const store = configureStore({
    reducer: {
        orders: ordersReducer,
        pendingOrders: pendingOrdersReducer,
        deviceLiveStatus: deviceLiveStatusReducer,

        [feastApi.reducerPath]: feastApi.reducer
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(feastApi.middleware, epicMiddleware)
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
