import { PopoverProps } from '@chakra-ui/popover';
import React from 'react';
import { BoxProps } from '@chakra-ui/layout';

export interface InfoPopoverProps {
    kind?: 'desktop' | 'mobile';
    placement?: PopoverProps['placement'];
    trigger?: PopoverProps['trigger'];
    body: React.ReactNode;
    children: React.ReactNode;
    maxWidth?: BoxProps['maxWidth'];
    isOpen?: boolean;
    fullWidth?: boolean;
}

export const popoverContainerStylingProps = {
    borderRadius: 'popover.content',
    bg: 'greyScheme.200',
    color: 'fonts.secondary',
    pt: 5,
    pl: 6,
    pr: 5,
    pb: 7,
    border: 'none',
    boxShadow: 'none',
    fontSize: 'md',
    lineHeight: '17px',
    width: 'auto'
};
