import React, { useMemo } from 'react';
import { Card } from '../../components/Card';
import { CardHeader, HeaderVariants } from '../CardHeader';
import { makeSelectLiveOrder, makeSelectDeliveryStatus } from '../../slices/orders/selectors';
import { useSelector } from 'react-redux';
import { DefaultBody } from './DefaultBody';
import { DeliveryStatus } from '../../types';

interface OrderCardProps {
    orderId: string;
}

export const OrderCard = ({ orderId }: OrderCardProps) => {
    const selectCurrentOrder = useMemo(() => makeSelectLiveOrder(orderId), [orderId]);
    const order = useSelector(selectCurrentOrder);

    const selectCurrentOrderStatus = useMemo(() => makeSelectDeliveryStatus(orderId), [orderId]);
    const orderStatus: DeliveryStatus = useSelector(selectCurrentOrderStatus);
    const { cancelled } = order;

    return (
        <Card
            header={<CardHeader order={order} variant={HeaderVariants.default} />}
            body={<DefaultBody order={order} />}
            deliveryStatus={orderStatus}
            cancelled={cancelled}
        />
    );
};
