import React from 'react';
import { ActionButton, ActionButtonVariant } from '../ActionButton';
import { Text } from '@chakra-ui/react';

interface EmailButtonProps {
    info: React.ReactNode;
    label: React.ReactNode;
    onClick?: () => void;
}

export const EmailButton = ({ info, label, onClick }: EmailButtonProps) => {
    return (
        <ActionButton
            w="75%"
            justifyContent="flex-start"
            variant={ActionButtonVariant.customer}
            info={info}
            onClick={onClick}
        >
            <Text pt={1} fontSize={12} pl={2} pr={3} fontWeight="bold">
                {label}
            </Text>
        </ActionButton>
    );
};
