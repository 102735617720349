import { BaseEpic } from '../../store';
import { combineEpics, Epic } from 'redux-observable';
import { filter, map, mergeMap, takeUntil, merge, of } from 'rxjs';
import { initializeOrders, orderAdded, orderChanged, orderRemoved, unwatchLiveOrders, watchLiveOrders } from './slice';
import { liveOrdersOnce$, ordersAdded$, ordersChanged$, ordersRemoved$ } from './api';

const epics: Record<string, BaseEpic> = {
    getLiveOrderEpic: (action$) => {
        return action$.pipe(
            filter(watchLiveOrders.match),
            mergeMap(() => {
                const collectionPrefix = process.env.REACT_APP_FIREBASE_RTDB_PREFIX as string;
                return liveOrdersOnce$(collectionPrefix).pipe(
                    mergeMap(({ lastTimestamp, value }) => {
                        return merge(
                            of(value).pipe(map(initializeOrders)),
                            ordersAdded$(collectionPrefix, lastTimestamp).pipe(map(orderAdded)),
                            ordersRemoved$(collectionPrefix).pipe(map(orderRemoved)),
                            ordersChanged$(collectionPrefix).pipe(map(orderChanged))
                        );
                    }),
                    takeUntil(action$.pipe(filter(unwatchLiveOrders.match)))
                );
            })
        );
    }
};

export const liveOrdersEpic = combineEpics(...Object.values(epics)) as Epic;
