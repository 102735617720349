import * as React from 'react';
import { Tabs as ChakraTabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react';
import { TabsType } from './TabsType';

export const DesktopTabs = ({ tabs }: { tabs: TabsType }) => (
    <ChakraTabs w="full" isLazy>
        <TabList>
            {tabs.map(({ label }) => (
                <Tab py={3.5} key={label}>
                    <Text fontWeight="bold" casing="uppercase">
                        {label}
                    </Text>
                </Tab>
            ))}
        </TabList>

        <TabPanels>
            {tabs.map(({ content, label }) => (
                <TabPanel key={label}>{content}</TabPanel>
            ))}
        </TabPanels>
    </ChakraTabs>
);
