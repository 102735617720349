import React from 'react';
import { Input, InputProps, Select } from '@chakra-ui/react';
import { isNil } from 'ramda';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateFilters } from 'slices/orders/slice';
import { FormValues } from './OrderTable';
import { SELECT_VALUES } from './consts';
import { ORDER_TYPE_COLUMN_ID } from 'containers/Dashboards/DesktopDashboard/const';

interface FilterInputProps {
    parameter?: string;
    name: string;
}

export const FilterInput = ({ name, parameter }: FilterInputProps & InputProps) => {
    const { register, handleSubmit, setValue } = useFormContext();
    const dispatch = useDispatch();
    const onSubmit = (data: FormValues) => {
        dispatch(updateFilters(data));
    };

    if (name !== ORDER_TYPE_COLUMN_ID) {
        return (
            <Input
                disabled={isNil(parameter)}
                type="text"
                {...register(name, {
                    onBlur: handleSubmit(onSubmit)
                })}
            />
        );
    }
    return (
        <Select
            variant="outline"
            {...register(name, {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue(name, e.target.value);
                    handleSubmit(onSubmit)();
                }
            })}
        >
            <option value="">All</option>
            {SELECT_VALUES.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Select>
    );
};
