import React, { useMemo } from 'react';
import { VStack } from '@chakra-ui/react';
import { Card } from 'components/Card';
import { useParams } from 'react-router-dom';
import { DeliveryStatus } from '../../types';
import { makeSelectLiveOrder } from '../../slices/orders/selectors';
import { useSelector } from 'react-redux';
import { CardHeader, HeaderVariants } from '../CardHeader';
import { DetailsBody } from './DetailsBody';

export const OrderDetails = () => {
    const { id }: any = useParams();
    const selectCurrentOrder = useMemo(() => makeSelectLiveOrder(id), [id]);
    const order = useSelector(selectCurrentOrder);

    return (
        <VStack w="full" p={2}>
            <Card
                header={<CardHeader order={order} variant={HeaderVariants.details} />}
                deliveryStatus={DeliveryStatus.handledByRestaurant}
                body={<DetailsBody order={order} />}
                cancelled={order?.cancelled}
            />
        </VStack>
    );
};
