import React from 'react';
import { Switch } from '@chakra-ui/react';
import { usePutConfirmationStatusMutation } from './api';

export interface OrderConfirmationProps {
    orderId: string;
    isConfirmed: boolean;
}

export const OrderConfirmation = ({ orderId, isConfirmed }: OrderConfirmationProps) => {
    const [updateStatus, { isLoading }] = usePutConfirmationStatusMutation();

    return (
        <Switch
            disabled={isLoading}
            isChecked={isConfirmed}
            onChange={() => updateStatus({ orderId, confirmed: !isConfirmed })}
        />
    );
};
