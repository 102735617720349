import React from 'react';
import { Text } from '@chakra-ui/react';
import { isNil } from 'ramda';

import { Manager } from '../../../../slices/orders/types';

interface ManagerInfoProps {
    order: any;
}
export const ManagerInfo = ({ order }: ManagerInfoProps) => {
    const { name: managerName, email, phone }: Manager = order?.hotel?.manager ?? {};
    return (
        <>
            {!isNil(managerName) && <Text>{managerName}</Text>}
            {!isNil(phone) && <Text>{phone}</Text>}
            {!isNil(email) && <Text>{email}</Text>}
        </>
    );
};
