import React from 'react';
import { Text } from '@chakra-ui/react';
import { ThemingProps } from '@chakra-ui/system/src/system.types';
import { Dict } from '@chakra-ui/utils';

type TextMenuOptionProps = {
    children: React.ReactNode;
    props?: ThemingProps & Dict;
};

export const TextMenuOption = ({ children, ...props }: TextMenuOptionProps) => (
    <Text userSelect="none" position="relative" whiteSpace="nowrap" paddingInlineStart={4} width="100%" {...props}>
        {children}
    </Text>
);
