import {
    UserCredential,
    Auth,
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signOut
} from '@firebase/auth';
import { app } from '../config';

export const auth: Auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const firebaseSignInWithEmailAndPassword = (email: string, password: string): Promise<UserCredential> =>
    signInWithEmailAndPassword(auth, email, password);

export const firebaseSignInWithGooglePopup = (): Promise<UserCredential> => signInWithPopup(auth, googleProvider);

export const firebaseCreateAccountWithEmailAndPassword = (email: string, password: string): Promise<UserCredential> =>
    createUserWithEmailAndPassword(auth, email, password);

export const firebaseSignOut = (): Promise<void> => signOut(auth);
