import React from 'react';
import { VStack } from '@chakra-ui/react';
import { Separator } from '../../../../components/Separator';
import { Expense } from '../../../../components/Expense/Expense';
import { Refunds } from './Refunds';
import PriceComponents from './PriceComponents';
import { formatPrice } from '../../../../utils';
import { isNil } from 'ramda';

interface OtherDetailsProps {
    order: any;
}

export const OtherDetails = ({ order }: OtherDetailsProps) => {
    const { grand_total_price = 0, feast_tip = 0, payment_method } = order?.price_components ?? {};

    return (
        <>
            <Separator label="OTHER" />
            <Refunds order={order} />
            <PriceComponents order={order} />
            <VStack w="full" justifyContent="flex-start" py={3} pl={4} pr={10} alignItems="flex-start">
                <Expense item="Paid" cost={formatPrice(grand_total_price)} />
            </VStack>
            <Separator />
            {!isNil(payment_method) && (
                <>
                    <VStack w="full" justifyContent="flex-start" pt={3} pb={1} pl={4} pr={10} alignItems="flex-start">
                        <Expense item="Paid via" cost={payment_method} />
                    </VStack>
                    <Separator />
                </>
            )}

            <VStack w="full" justifyContent="flex-start" pb={0} pt={1} pl={4} pr={10} alignItems="flex-start">
                <Expense item="Tip" cost={formatPrice(feast_tip)} />
            </VStack>
        </>
    );
};
