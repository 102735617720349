import React from 'react';
import { Route } from 'react-router';
import { Redirect, RouteProps } from 'react-router-dom';
import { isUserAuthenticated } from '../../api/auth';

export const PrivateRoute = (props: RouteProps) => {
    if (!isUserAuthenticated()) {
        return <Redirect to="/login" />;
    }

    return <Route {...props} />;
};
