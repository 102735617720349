import {
    limitToFirst,
    limitToLast,
    startAt,
    startAfter,
    endAt,
    endBefore,
    equalTo,
    orderByChild,
    orderByKey,
    orderByValue,
    query as firebaseQuery,
    getDatabase,
    ref,
    get,
    set,
    update,
    onValue,
    runTransaction,
    remove,
    serverTimestamp,
    push,
    onChildAdded,
    onChildChanged,
    onChildRemoved,
    onChildMoved
} from '@firebase/database';
import type {
    QueryConstraint,
    DatabaseReference,
    DataSnapshot,
    TransactionOptions,
    TransactionResult,
    ThenableReference,
    Query
} from '@firebase/database';
import { fromPairs, repeat, zip } from 'ramda';
import { map, filter, Observable } from 'rxjs';
import type { OperatorFunction } from 'rxjs';
import { app } from '../config';
import { ChildEventType } from '../enums';
import { ChildEventOptions, TypedChildEvent } from '../models';

export type Path = string | string[];

const normalizePath = (path: Path): string => (Array.isArray(path) ? `/${path.join('/')}` : path);

export const getRef = (path?: Path): DatabaseReference => (path ? ref(database, normalizePath(path)) : ref(database));

export const getQuery = (path?: Path, ...constraints: QueryConstraint[]): Query =>
    firebaseQuery(getRef(path), ...constraints);

export {
    orderByChild,
    orderByKey,
    orderByValue,
    limitToFirst,
    limitToLast,
    startAt,
    startAfter,
    endAt,
    endBefore,
    equalTo
};

export const database = getDatabase(app);

export const setValue = <T>(path: Path, data: T): Promise<void> => set(getRef(path), data);

export const getSnapshotStream = (query: Query): Observable<DataSnapshot> =>
    new Observable((subscriber) =>
        onValue(
            query,
            (snapshot) => {
                subscriber.next(snapshot);
            },
            (error) => {
                subscriber.error(error);
            }
        )
    );

export const getOnceStream = (query: Query): Observable<DataSnapshot> =>
    new Observable((subscriber) =>
        onValue(
            query,
            (snapshot) => {
                subscriber.next(snapshot);
            },
            (error) => {
                subscriber.error(error);
            },
            { onlyOnce: true }
        )
    );

export const getValueStream = <T>(query: Query): Observable<T> =>
    getSnapshotStream(query).pipe(map((snapshot) => snapshot.val() as T));

export const getValueFromDb = (path: Path): Promise<DataSnapshot> => get(getRef(path));

export const getValue = (query: Query): Promise<DataSnapshot> =>
    new Promise((resolve, reject) => {
        onValue(
            query,
            (snapshot) => {
                resolve(snapshot);
            },
            (error) => {
                reject(error);
            },
            { onlyOnce: true }
        );
    });

export const performUpdates = (paths: Path[], data: unknown | unknown[]): Promise<void> => {
    if (Array.isArray(data)) {
        if (data.length === paths.length) {
            return update(getRef(), fromPairs(zip(paths.map(normalizePath), data)));
        }

        throw new Error('Provide the same number of paths and data objects or just one object');
    } else {
        return update(getRef(), fromPairs(zip(paths.map(normalizePath), repeat(data, paths.length))));
    }
};

export const deleteDataFromDb = (path: Path): Promise<void> => remove(getRef(path));

export const performTransaction = <T>(
    path: Path,
    callback: (data: T) => unknown,
    options?: TransactionOptions
): Promise<TransactionResult> => runTransaction(getRef(path), callback, options);

export const getServerTimestamp = (): Record<string, string> => serverTimestamp() as Record<string, string>;

export const pushDataToDbList = <T>(path: Path, data: T): ThenableReference => push(getRef(path), data);

export const childEventsWithType = (query: Query, options?: Partial<ChildEventOptions>): Observable<TypedChildEvent> =>
    new Observable<TypedChildEvent>((subscriber) => {
        const listChangeOptions: ChildEventOptions = options
            ? {
                  child_added: false,
                  child_changed: false,
                  child_removed: false,
                  child_moved: false,
                  ...options
              }
            : {
                  child_added: true,
                  child_changed: true,
                  child_removed: true,
                  child_moved: true
              };

        const unsubAdded = listChangeOptions.child_added
            ? onChildAdded(
                  query,
                  (snapshot) => subscriber.next({ eventType: ChildEventType.child_added, snapshot }),
                  (error) => subscriber.error(error)
              )
            : () => {};

        const unsubChanged = listChangeOptions.child_changed
            ? onChildChanged(
                  query,
                  (snapshot) => subscriber.next({ eventType: ChildEventType.child_changed, snapshot }),
                  (error) => subscriber.error(error)
              )
            : () => {};

        const unsubRemoved = listChangeOptions.child_removed
            ? onChildRemoved(
                  query,
                  (snapshot) => subscriber.next({ eventType: ChildEventType.child_removed, snapshot }),
                  (error) => subscriber.error(error)
              )
            : () => {};

        const unsubMoved = listChangeOptions.child_moved
            ? onChildMoved(
                  query,
                  (snapshot) => subscriber.next({ eventType: ChildEventType.child_moved, snapshot }),
                  (error) => subscriber.error(error)
              )
            : () => {};

        return () => {
            unsubAdded();
            unsubChanged();
            unsubRemoved();
            unsubMoved();
        };
    });

export const childEvents = (query: Query, options?: Partial<ChildEventOptions>): Observable<DataSnapshot> =>
    childEventsWithType(query, options).pipe(stripChildEventTypes());

const isTypedChildEvent = (data: DataSnapshot | TypedChildEvent): data is TypedChildEvent => Array.isArray(data);

export const stripChildEventTypes = (): OperatorFunction<TypedChildEvent, DataSnapshot> =>
    map((typedSnapshot) => typedSnapshot.snapshot);

export const filterChildEventTypes = (
    types: Partial<ChildEventOptions>
): OperatorFunction<TypedChildEvent, TypedChildEvent> =>
    filter((typedSnapshot) => Object.keys(types).includes(typedSnapshot.eventType));

export const filterChildEventType =
    (type: ChildEventType): OperatorFunction<TypedChildEvent, DataSnapshot> =>
    (source) =>
        source.pipe(filterChildEventTypes({ [type]: true }), stripChildEventTypes());

export const snapshotValues = <T>(): OperatorFunction<DataSnapshot | TypedChildEvent, T | null> =>
    map((snapshot) => {
        const properSnapshot = isTypedChildEvent(snapshot) ? snapshot.snapshot : snapshot;
        return properSnapshot.exists() ? (properSnapshot.val() as T) : null;
    });

export const snapshotKeys = (): OperatorFunction<DataSnapshot | TypedChildEvent, string | null> =>
    map((snapshot) => (isTypedChildEvent(snapshot) ? snapshot.snapshot.key : snapshot.key));

export const snapshotEntries = <T>(): OperatorFunction<DataSnapshot | TypedChildEvent, [string, T] | null> =>
    map((snapshot) => {
        const properSnapshot = isTypedChildEvent(snapshot) ? snapshot.snapshot : snapshot;
        return properSnapshot.key ? [properSnapshot.key, properSnapshot.val() as T] : null;
    });

export const snapshotExists = (): OperatorFunction<DataSnapshot | TypedChildEvent, DataSnapshot | TypedChildEvent> =>
    filter((snapshot) => (isTypedChildEvent(snapshot) ? snapshot.snapshot.exists() : snapshot.exists()));
