import { injectEndpoints } from '../../api/service';
import { pick } from 'ramda';
import { orderCallRequestChanged } from '../../slices/orders/slice';
import { createSuccessToast } from '../../utils/createSuccessToast';
import { createErrorToast } from '../../utils';

export const callRequestApi = injectEndpoints({
    endpoints: (builder) => ({
        putChangeCallRequest: builder.mutation<any, any>({
            query: ({ orderId, callRestaurant }) => ({
                url: `/orders/${orderId}`,
                method: 'put',
                data: { order: { id: orderId, call_restaurant: callRestaurant } }
            }),

            transformResponse: pick(['id', 'confirmed']),

            onQueryStarted: async ({ orderId, callRestaurant }, { dispatch, queryFulfilled }) => {
                dispatch(orderCallRequestChanged({ orderId, callRestaurant }));
                createSuccessToast('Request to call the restaurant unchecked');
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    dispatch(orderCallRequestChanged({ orderId, callRestaurant: !callRestaurant }));
                    createErrorToast('Request to call the restaurant uncheck failed', err.error.status);
                }
            }
        })
    })
});

export const { usePutChangeCallRequestMutation } = callRequestApi;
