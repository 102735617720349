import * as React from 'react';
import { IconProps as ChakraIconProps } from '@chakra-ui/react';
import {
    BackIcon,
    BellIcon,
    CancelledIcon,
    CheckIcon,
    CircleIcon,
    CollectIcon,
    DashIcon,
    FeastLogoIcon,
    FraudIcon,
    HotelIcon,
    InfoIcon,
    MenuIcon,
    MessageIcon,
    MessageWithSquareIcon,
    NotesIcon,
    PaymentIcon,
    PhoneDotIcon,
    PhoneIcon,
    PhoneWithSquareIcon,
    PinPointWithSquareIcon,
    RefundIcon,
    RequestedSupportIcon,
    RestaurantIcon,
    RIcon,
    RightArrowIcon,
    SearchIcon,
    SignalIcon,
    ThreeDotsIcon
} from './icons';

type IconName =
    | 'BackIcon'
    | 'BellIcon'
    | 'CancelledIcon'
    | 'CheckIcon'
    | 'CircleIcon'
    | 'CollectIcon'
    | 'DashIcon'
    | 'FeastLogoIcon'
    | 'FraudIcon'
    | 'HotelIcon'
    | 'InfoIcon'
    | 'MenuIcon'
    | 'MessageIcon'
    | 'MessageWithSquareIcon'
    | 'NotesIcon'
    | 'PaymentIcon'
    | 'PhoneDotIcon'
    | 'PhoneIcon'
    | 'PhoneWithSquareIcon'
    | 'PinPointWithSquareIcon'
    | 'RefundIcon'
    | 'RequestedSupportIcon'
    | 'RestaurantIcon'
    | 'RIcon'
    | 'RightArrowIcon'
    | 'SearchIcon'
    | 'SignalIcon'
    | 'ThreeDotsIcon';

const icons = {
    BackIcon,
    BellIcon,
    CancelledIcon,
    CheckIcon,
    CircleIcon,
    CollectIcon,
    DashIcon,
    FeastLogoIcon,
    FraudIcon,
    HotelIcon,
    InfoIcon,
    MenuIcon,
    MessageIcon,
    MessageWithSquareIcon,
    NotesIcon,
    PaymentIcon,
    PhoneDotIcon,
    PhoneIcon,
    PhoneWithSquareIcon,
    PinPointWithSquareIcon,
    RefundIcon,
    RequestedSupportIcon,
    RestaurantIcon,
    RIcon,
    RightArrowIcon,
    SearchIcon,
    SignalIcon,
    ThreeDotsIcon
};

interface IconProps {
    name: IconName;
    size?: ChakraIconProps['boxSize'];
    color?: ChakraIconProps['color'];
}

export const Icon = ({ name, size, color, ...props }: IconProps) => {
    if (name === 'CancelledIcon') {
        return <CancelledIcon w="auto" h={size} {...props} />;
    }

    const IconToRender = icons[name];
    return <IconToRender boxSize={size} color={color} {...props} />;
};

Icon.defaultProps = {
    size: 6
};
