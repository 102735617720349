import { Box } from '@chakra-ui/layout';
import React from 'react';

export const FeastUpperStrokes = () => (
    <Box position="absolute" top="0" right="0" maxW="38%" display={{ base: 'none', lg: 'initial' }}>
        <svg width="486" height="429" viewBox="0 0 486 429" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M276.853 89.5573L526.776 -37.7848" stroke="#254994" strokeWidth="40" strokeLinecap="round" />
            <path d="M47.9877 304.795L868.062 -113.054" stroke="#254994" strokeWidth="40" strokeLinecap="round" />
            <path d="M336.71 272.66L1156.78 -145.189" stroke="#254994" strokeWidth="40" strokeLinecap="round" />
        </svg>
    </Box>
);
