import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const FraudIcon = createIcon({
    displayName: 'FraudIcon',
    viewBox: '0 0 21 24',
    path: (
        <>
            <rect width="20" height="24" x="0.556" fill="#F94C4C" rx="6" />
            <path
                fill="#fff"
                d="M13.658 8.734c0 .157-.008.29-.024.399a.727.727 0 01-.062.25.368.368 0 01-.094.14.187.187 0 01-.125.047H10.05v2.72h3.101a.21.21 0 01.125.038.3.3 0 01.094.133.73.73 0 01.062.25c.016.104.024.234.024.39 0 .157-.008.287-.024.391a.813.813 0 01-.062.258.363.363 0 01-.094.148.21.21 0 01-.125.04H10.05v3.765a.24.24 0 01-.047.149.376.376 0 01-.172.109 1.632 1.632 0 01-.313.062c-.13.016-.297.024-.5.024-.198 0-.364-.008-.5-.024a1.633 1.633 0 01-.312-.062.445.445 0 01-.172-.11.24.24 0 01-.047-.148V8.54c0-.23.057-.393.172-.492a.644.644 0 01.437-.156h4.758c.047 0 .089.015.125.046a.3.3 0 01.094.141.744.744 0 01.063.258c.015.11.023.242.023.398z"
            />
        </>
    )
});
