import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { Separator } from '../Separator';
import { MobileInfoPopover, DesktopInfoPopover } from './components';
import { InfoPopoverProps } from './const';

export const InfoPopover = ({
    placement,
    children,
    body,
    isOpen,
    trigger,
    kind,
    maxWidth,
    fullWidth
}: InfoPopoverProps) => {
    const isBodyNil = isNil(body);
    const isBodyFalse = typeof body === 'boolean' && !body;
    const isBodyEmpty = (typeof body === 'string' || Array.isArray(body)) && isEmpty(body);

    if (isBodyNil || isBodyFalse || isBodyEmpty) {
        return <>{children}</>;
    }

    const bodyChildren = React.Children.toArray(body);
    const bodyElement = bodyChildren.map((child, idx) => (
        <React.Fragment key={idx}>
            {child}
            {idx !== bodyChildren.length - 1 && <Separator hasOpacity />}
        </React.Fragment>
    ));

    if (kind === 'mobile') {
        return (
            <MobileInfoPopover maxWidth={maxWidth} body={bodyElement}>
                {children}
            </MobileInfoPopover>
        );
    }

    return (
        <DesktopInfoPopover
            maxWidth={maxWidth}
            body={bodyElement}
            placement={placement}
            trigger={trigger}
            isOpen={isOpen}
            fullWidth={fullWidth}
        >
            {children}
        </DesktopInfoPopover>
    );
};

InfoPopover.defaultProps = {
    trigger: 'hover',
    kind: 'desktop',
    maxWidth: 'popover.maxWidth.info',
    fullWidth: false
};
