import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/layout';
import { isNil } from 'ramda';
import { CellProps } from '../../types';
import { makeSelectOrderStatus } from '../../../../../slices/orders/selectors';
import { Icon } from '../../../../../components/Icon';
import { InfoPopover } from '../../../../../components/InfoPopover';
import { OrderConfirmation } from '../../../../OrderConfirmation';
import { CallRequest } from '../../../../CallRequest';
import { CallButtonVariants } from '../../../../../components/CallButton';
import { OrderStatusPopoverBody } from './OrderStatusPopoverBody';

export const OrderStatus = ({ orderId }: CellProps) => {
    const selectOrderStatus = useMemo(() => makeSelectOrderStatus(orderId), [orderId]);
    const { confirmed, cancelled, call_restaurant, primary_number, extended_by_order } = useSelector(selectOrderStatus);
    const selectBelongingOrderStatus = useMemo(() => makeSelectOrderStatus(extended_by_order), [extended_by_order]);
    const { confirmed: belongingOrderConfirmed } = useSelector(selectBelongingOrderStatus);

    const callPopoverBody = (
        <Box textAlign="center">
            <Text fontSize="md" color="red" fontWeight="bold">
                Remove on click
            </Text>
            <Text>{primary_number}</Text>
        </Box>
    );

    return (
        <HStack align="center">
            <InfoPopover body={<OrderStatusPopoverBody confirmed={confirmed} main={!isNil(extended_by_order)} />}>
                <Box>
                    <OrderConfirmation orderId={orderId} isConfirmed={confirmed} />
                </Box>
            </InfoPopover>
            {!isNil(extended_by_order) && (
                <InfoPopover body={<OrderStatusPopoverBody confirmed={belongingOrderConfirmed} belonging />}>
                    <Box>
                        <OrderConfirmation orderId={extended_by_order} isConfirmed={belongingOrderConfirmed} />
                    </Box>
                </InfoPopover>
            )}

            {call_restaurant && (
                <InfoPopover body={callPopoverBody}>
                    <Box>
                        <CallRequest
                            orderId={orderId}
                            callRestaurant={call_restaurant}
                            variant={CallButtonVariants.requested}
                        />
                    </Box>
                </InfoPopover>
            )}

            {cancelled && <Icon name="CancelledIcon" size={7} />}
        </HStack>
    );
};
