import React from 'react';
import { Button, HStack, IconButton } from '@chakra-ui/react';
import { ButtonProps } from '@chakra-ui/react';
import { Icon } from '../../../components/Icon';
import { InfoPopover } from '../../../components/InfoPopover';

export enum ActionButtonVariant {
    customer = 'customer',
    restaurant = 'restaurant'
}

interface ActionButtonProps {
    children?: React.ReactNode;
    variant: ActionButtonVariant;
    info: React.ReactNode;
}

export const ActionButton = ({ children, info, variant, ...props }: ActionButtonProps & ButtonProps) => {
    return (
        <HStack w="full" justifyContent="space-between">
            <Button
                variant="solid"
                py={3}
                px={3}
                {...props}
                leftIcon={
                    <Icon size={5} name={variant === ActionButtonVariant.customer ? 'MessageIcon' : 'PhoneIcon'} />
                }
            >
                {children}
            </Button>

            <InfoPopover body={info} kind="mobile">
                <IconButton icon={<Icon name="InfoIcon" size={5} />} aria-label="Icon" />
            </InfoPopover>
        </HStack>
    );
};
