import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQueryFactory } from './utils';
import { api } from './config';
import { createSuccessToast } from '../utils/createSuccessToast';
import { createErrorToast } from '../utils';
import { isNil } from 'ramda';

export const tostifyOnQueryStarted =
    (succText: string | undefined | null, errText: string | undefined | null) =>
    async (arg: any, { queryFulfilled }: any) => {
        if (!isNil(succText)) {
            createSuccessToast(succText);
        }

        try {
            await queryFulfilled;
        } catch (err: any) {
            const {
                error: { status }
            } = err;

            if (!isNil(errText)) {
                createErrorToast(errText, status);
            }
        }
    };

export const feastApi = createApi({
    reducerPath: 'feastApi',
    baseQuery: axiosBaseQueryFactory(api),
    endpoints: (builder) => ({
        getAppSettings: builder.query<any, void>({
            query: () => ({
                url: '/app_settings',
                method: 'get'
            })
        }),

        callToTurnTabletOn: builder.mutation<any, any>({
            query: ({ restaurantId, orderId }) => ({
                url: `/restaurants/call_to_turn_tablet_on/${restaurantId}`,
                method: 'post',
                data: { id: restaurantId, order_id: orderId }
            }),
            onQueryStarted: tostifyOnQueryStarted(
                'Call to turn tablet on request sent',
                'Call to turn tablet on request failed'
            )
        }),
        callToConfirmOrder: builder.mutation<any, any>({
            query: ({ restaurantId, orderId }) => ({
                url: `/restaurants/call_to_confirm_order/${restaurantId}`,
                method: 'post',
                data: { id: restaurantId, order_id: orderId }
            }),
            onQueryStarted: tostifyOnQueryStarted(
                'Call to confirm order request sent',
                'Call to confirm order request failed'
            )
        }),

        sendCustomerUnreachableEmail: builder.mutation<any, any>({
            query: ({ userId, orderId }) => ({
                url: `/customer_unreachable/${userId}`,
                method: 'post',
                data: { id: userId, order_id: orderId }
            }),
            onQueryStarted: tostifyOnQueryStarted(
                'Send customer unreachable email request sent',
                'Send customer unreachable email request failed'
            )
        }),

        sendDelayEmail: builder.mutation<any, any>({
            query: ({ userId, orderId }) => ({
                url: `/delay_email/${userId}`,
                method: 'post',
                data: { id: userId, order_id: orderId }
            }),
            onQueryStarted: tostifyOnQueryStarted('Send delay email request sent', 'Send delay email request failed')
        }),

        sendFraudEmail: builder.mutation<any, any>({
            query: ({ userId, orderId }) => ({
                url: `/fraud_email/${userId}`,
                method: 'post',
                data: { id: userId, order_id: orderId }
            }),
            onQueryStarted: tostifyOnQueryStarted('Send fraud email request sent', 'Send fraud email request failed')
        }),

        sendFailEmail: builder.mutation<any, any>({
            query: ({ userId, orderId }) => ({
                url: `/fail_email/${userId}`,
                method: 'post',
                data: { id: userId, order_id: orderId }
            }),
            onQueryStarted: tostifyOnQueryStarted('Send fail email request sent', 'Send fail email request failed')
        })
    })
});

export const { injectEndpoints } = feastApi;

export const {
    useCallToTurnTabletOnMutation,
    useCallToConfirmOrderMutation,

    useSendCustomerUnreachableEmailMutation,
    useSendDelayEmailMutation,
    useSendFraudEmailMutation,
    useSendFailEmailMutation
} = feastApi;
