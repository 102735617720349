import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    selectAreLiveOrdersLoading,
    selectFilters,
    selectIsFilterView,
    selectOrdersIds
} from '../../../../slices/orders/selectors';
import { ORDER_TABLE_COLUMNS_CONFIG } from '../const';
import { OrderTable } from 'components/OrderTable/OrderTable';
import { OrderRow } from '../OrderRow/OrderRow';
import { useLazyGetFilteredOrdersQuery } from 'slices/orders/api';
import { isEmpty } from 'ramda';

export const TableView = () => {
    const isFilterView = useSelector(selectIsFilterView);
    const filters = useSelector(selectFilters);
    const allOrdersIds = useSelector(selectOrdersIds);
    const areLiveOrdersLoading = useSelector(selectAreLiveOrdersLoading);

    const [triggerFilteredOrdersQuery, filteredOrdersResult] = useLazyGetFilteredOrdersQuery();
    const { isFetching: isFetchingFilteredOrders } = filteredOrdersResult;

    useEffect(() => {
        if (isFilterView) {
            triggerFilteredOrdersQuery(filters);
        }
    }, [triggerFilteredOrdersQuery, isFilterView, filters]);

    const showLoading = (isFilterView && isFetchingFilteredOrders) || (!isFilterView && areLiveOrdersLoading);

    const getStateInfo = () => {
        if (showLoading) {
            return 'Loading...';
        }

        if (isEmpty(allOrdersIds)) {
            return 'No Results...';
        }
    };

    return (
        <>
            <OrderTable columns={ORDER_TABLE_COLUMNS_CONFIG}>
                {!showLoading && allOrdersIds.map((orderId) => <OrderRow key={orderId} orderId={orderId} />)}
            </OrderTable>

            {getStateInfo()}
        </>
    );
};
