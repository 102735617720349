import axios from 'axios';
import { isNil } from 'ramda';
import { ALL_TOKENS, AUTH_API_ENDPOINTS, USER_ID_KEY, USER_NAME_KEY, API_CONFIG } from './const';
import { ACCESS_TOKEN_KEY } from './const';

export const authApi = axios.create(API_CONFIG);

export const getTokens = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const userId = localStorage.getItem(USER_ID_KEY);
    const userName = localStorage.getItem(USER_NAME_KEY);

    return {
        accessToken,
        userId,
        userName,
        hasTokens: !isNil(accessToken) && !isNil(userId) && !isNil(userName)
    };
};

export const setTokens = (accessToken: string, userId: string, userName: string) => {
    setAccessToken(accessToken, userId, userName);
};

export const setAccessToken = (accessToken: string, userId: string, userName: string) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(USER_ID_KEY, userId);
    localStorage.setItem(USER_NAME_KEY, userName);
};

export const flushTokens = () => {
    ALL_TOKENS.forEach((tokenKey) => {
        localStorage.removeItem(tokenKey);
    });
};

export const loginRequest = (email: string, password: string, neverLogout = true) =>
    authApi.post(AUTH_API_ENDPOINTS.LOGIN, {
        email,
        password,
        never_logout: neverLogout
    });

export const logoutRequest = () => {
    const { accessToken, userName } = getTokens();

    return authApi.delete(AUTH_API_ENDPOINTS.AUTHENTICATE, {
        headers: {
            Authorization: accessToken as string
        },
        params: {
            user: userName
        }
    });
};

export const isUserAuthenticated = () => {
    const { hasTokens } = getTokens();
    return hasTokens;
};
