import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { signOut } from './api';

export const LogoutPage = () => {
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await signOut();
                history.push('/login');
            } catch (error) {
                console.error(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>Logging out...</>;
};
