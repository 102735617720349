import React, { useMemo } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { StackProps } from '@chakra-ui/layout';
import { isNil } from 'ramda';
import { DeviceLiveStatus } from '../DeviceLiveStatus';
import { OrderConfirmation } from '../OrderConfirmation';
import { CallButton, CallButtonVariants } from '../../components/CallButton';
import { CallRequest } from '../CallRequest';
import { makeSelectOrderStatus } from '../../slices/orders/selectors';
import { useSelector } from 'react-redux';

export enum OrderCardQuickActionsType {
    details = 'details',
    default = 'default'
}
interface OrderCardQuickActionsProps {
    order: any;
    type: OrderCardQuickActionsType;
}

export const OrderCardQuickActions = ({ order, type, ...props }: OrderCardQuickActionsProps & StackProps) => {
    const { primary_number, name } = order?.restaurant ?? {};
    const { id, extended_by_order } = order;
    const callRestaurant = order?.call_restaurant ?? false;
    const selectBelongingOrderStatus = useMemo(() => makeSelectOrderStatus(extended_by_order), [extended_by_order]);
    const { confirmed: belongingOrderConfirmed } = useSelector(selectBelongingOrderStatus);

    return (
        <HStack w="full" justifyContent="space-between" alignItems="center" {...props}>
            {!isNil(name) && <Text fontWeight="normal">{name}</Text>}
            <HStack align="center" justify="center">
                <OrderConfirmation orderId={order.id} isConfirmed={order?.confirmed} />
                {!isNil(extended_by_order) && (
                    <OrderConfirmation orderId={extended_by_order} isConfirmed={belongingOrderConfirmed} />
                )}
                <DeviceLiveStatus restaurantId={order?.restaurant?.id} />
                {callRestaurant ? (
                    <CallRequest
                        orderId={id}
                        callRestaurant={callRestaurant}
                        phone={primary_number}
                        variant={CallButtonVariants.requested}
                    />
                ) : (
                    <CallButton phone={primary_number} />
                )}
            </HStack>
        </HStack>
    );
};
