import { flushTokens, logoutRequest, resetApi } from '../../api';

export const signOut = async () => {
    try {
        await logoutRequest();
    } catch (error) {
        console.error(error);
    } finally {
        flushTokens();
        resetApi();
    }
};
