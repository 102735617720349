import { Popover, PopoverBody, PopoverContent, PopoverProps, PopoverTrigger } from '@chakra-ui/react';
import React from 'react';
import { isEmpty, isNil } from 'ramda';

export interface MenuPopoverProps {
    trigger?: PopoverProps['trigger'];
    body: React.ReactNode;
    children: React.ReactNode;
    isOpen?: boolean;
}

export const MenuPopover = ({ children, body, isOpen, trigger }: MenuPopoverProps) => {
    const isPopoverOpen = isOpen ? true : undefined;

    const isBodyNil = isNil(body);
    const isBodyFalse = typeof body === 'boolean' && !body;
    const isBodyEmpty = (typeof body === 'string' || Array.isArray(body)) && isEmpty(body);

    if (isBodyNil || isBodyFalse || isBodyEmpty) {
        return <>{children}</>;
    }

    return (
        <Popover isOpen={isPopoverOpen} trigger={trigger} isLazy>
            <PopoverTrigger>{children}</PopoverTrigger>

            <PopoverContent
                maxW="popover.maxWidth.menu"
                bg="greyScheme.200"
                p={0}
                borderRadius={0}
                boxShadow="none"
                _focus={{ outline: 'none' }}
            >
                <PopoverBody p={0}>{body}</PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

MenuPopover.defaultProps = {
    trigger: 'hover'
};
