import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { App } from 'containers/App';
import { store } from 'store';
import { theme } from 'theme';

import './theme/style.css';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <ChakraProvider theme={theme}>
                    <App />
                </ChakraProvider>
            </Provider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
