import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const BellIcon = createIcon({
    displayName: 'BellIcon',
    viewBox: '0 0 18 20',
    path: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.72 11.403l1.801 1.804a1.63 1.63 0 01.352 1.783 1.629 1.629 0 01-1.51 1.009H13v.34C13 18.359 11.206 20 9 20s-4-1.641-4-3.66V16H1.637a1.632 1.632 0 01-1.512-1.01 1.631 1.631 0 01.354-1.782l1.801-1.804.001-4.677c0-1.934.838-3.778 2.302-5.06A6.722 6.722 0 019.9.059c3.318.439 5.82 3.396 5.82 6.877v4.467zM2.515 13.999l1.18-1.182c.378-.378.586-.88.586-1.414V6.726c0-1.357.59-2.654 1.62-3.556a4.66 4.66 0 013.737-1.13c2.327.31 4.082 2.414 4.082 4.896v4.467c0 .534.208 1.036.585 1.413l1.181 1.183H2.516zM11 16.339c0 .9-.916 1.66-2 1.66s-2-.76-2-1.66V16h4v.34z"
            clipRule="evenodd"
        />
    )
});
