import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectCustomer } from 'slices/orders/selectors';
import { CellProps } from '../../types';
import { CustomerPopover } from '../../../../CustomerPopover';
import { isEmpty } from 'ramda';
import { InfoPopover } from '../../../../../components/InfoPopover';
import { Box, Link } from '@chakra-ui/react';
import { Icon } from '../../../../../components/Icon';

export const Customer = ({ orderId }: CellProps) => {
    const selectCustomer = useMemo(() => makeSelectCustomer(orderId), [orderId]);
    const customer = useSelector(selectCustomer);
    const { first_name, last_name, note, id: userId } = customer;

    return (
        <Flex alignItems="center" position="relative" cursor="default">
            <CustomerPopover customer={customer} orderId={orderId}>
                <Link href={`${process.env.REACT_APP_HOST_URL}/admin/users/${userId}`} target="_blank">
                    {first_name} {last_name}
                </Link>
            </CustomerPopover>

            {!isEmpty(note) && (
                <InfoPopover body={note}>
                    <Box position="absolute" right={0} cursor="pointer">
                        <Icon name="NotesIcon" size={5} />
                    </Box>
                </InfoPopover>
            )}
        </Flex>
    );
};
