import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const InfoIcon = createIcon({
    displayName: 'InfoIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <circle cx="10" cy="10" r="9" stroke="currentColor" strokeWidth="2" fill="none" />
            <circle cx="10" cy="6" r="1" fill="currentColor" />
            <rect x="9" y="8" width="2" height="8" rx="1" fill="currentColor" />\
        </>
    )
});
