import { IconProps } from '@chakra-ui/react';
import { Icon } from 'components/Icon';
import * as React from 'react';
import { DeliveryStatus as DeliveryStatusEnum } from '../../types';

type DeliveryStatusProps = {
    status: DeliveryStatusEnum;
    size?: IconProps['boxSize'];
};

export const DeliveryStatus = ({ size = 5, status }: DeliveryStatusProps) => {
    return <Icon name="CircleIcon" color={`deliveryStatus.${status}`} size={size} />;
};
