import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PinPointWithSquareIcon = createIcon({
    displayName: 'PinPointWithSquareIcon',
    viewBox: '0 0 21 25',
    path: (
        <>
            <path
                d="M6.729 12H2.667C1.196 12 0 10.804 0 9.333V2.667C0 1.196 1.196 0 2.667 0H9.333C10.804 0 12 1.196 12 2.667V6.401H10V2.667C10 2.299 9.701 2 9.333 2H2.667C2.299 2 2 2.299 2 2.667V9.333C2 9.701 2.299 10 2.667 10H6.729V12Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.349 24.758C12.049 24.501 5 18.397 5 12.922C5 8.553 8.589 5 13 5C17.411 5 21 8.553 21 12.922C21 18.397 13.951 24.501 13.651 24.758C13.463 24.919 13.232 25 13 25C12.768 25 12.537 24.919 12.349 24.758ZM13 22.646C11.325 21.062 7 16.615 7 12.922C7 9.657 9.691 7 13 7C16.309 7 19 9.657 19 12.922C19 16.615 14.675 21.062 13 22.646ZM9.5 12.4995C9.5 10.5695 11.07 8.9995 13 8.9995C14.93 8.9995 16.5 10.5695 16.5 12.4995C16.5 14.4295 14.93 15.9995 13 15.9995C11.07 15.9995 9.5 14.4295 9.5 12.4995ZM11.5 12.4995C11.5 13.3265 12.173 13.9995 13 13.9995C13.827 13.9995 14.5 13.3265 14.5 12.4995C14.5 11.6725 13.827 10.9995 13 10.9995C12.173 10.9995 11.5 11.6725 11.5 12.4995Z"
                fill="currentColor"
            />
        </>
    )
});
