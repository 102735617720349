import { childEvents, getOnceStream, getQuery, getRef } from '../../firebase';
import { map as rMap, keys, last } from 'ramda';
import { map } from 'rxjs';
import { RestaurantDeviceStatus } from './types';
import { orderByKey, startAfter } from '@firebase/database';

export const deviceStatusOnce$ = (collectionPrefix: string) => {
    return getOnceStream(getRef(`${collectionPrefix}/device_presence`)).pipe(
        map((snapshot) => {
            const snapshotValue = snapshot.val() ?? {};
            const ids = keys(snapshotValue);
            const lastId = (last(ids) ?? '') as string;
            const statuses = rMap(
                (status: any) => (status ? RestaurantDeviceStatus.on : RestaurantDeviceStatus.off),
                snapshotValue
            );
            return { lastId, statuses };
        })
    );
};

export const deviceStatusAdded$ = (collectionPrefix: string, lastId: string) => {
    const query = getQuery(`${collectionPrefix}/device_presence`, orderByKey(), startAfter(lastId));

    return childEvents(query, { child_added: true }).pipe(
        map((snapshot) => {
            const active = snapshot.val();
            const restaurantId = snapshot.key as string;

            return {
                restaurantId,
                status: active ? RestaurantDeviceStatus.on : RestaurantDeviceStatus.off
            };
        })
    );
};

export const deviceStatusChanged$ = (collectionPrefix: string) => {
    const query = getQuery(`${collectionPrefix}/device_presence`);

    return childEvents(query, { child_changed: true }).pipe(
        map((snapshot) => {
            const active = snapshot.val();
            const restaurantId = snapshot.key as string;

            return {
                restaurantId,
                status: active ? RestaurantDeviceStatus.on : RestaurantDeviceStatus.off
            };
        })
    );
};
