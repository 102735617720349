import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SignalIcon = createIcon({
    displayName: 'SignalIcon',
    viewBox: '0 0 20 17',
    path: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.766 13.644c.355-.422.3-1.053-.122-1.41C2.938 10.8 2 8.941 2 7c0-1.94.938-3.8 2.644-5.234.422-.357.477-.988.122-1.41a1.002 1.002 0 00-1.41-.122C1.192 2.056 0 4.46 0 7c0 2.541 1.192 4.944 3.356 6.766a1 1 0 001.41-.122zm11.878.122C18.808 11.944 20 9.541 20 7c0-2.54-1.192-4.944-3.356-6.766a1.004 1.004 0 00-1.41.122c-.355.422-.3 1.053.122 1.41C17.062 3.2 18 5.06 18 7c0 1.941-.938 3.8-2.644 5.234a1.002 1.002 0 00-.122 1.41 1.002 1.002 0 001.41.122zM5.5 7c0-1.193.584-2.343 1.644-3.235a1 1 0 00-1.288-1.53C4.337 3.515 3.5 5.206 3.5 7c0 1.794.837 3.486 2.356 4.765a.999.999 0 101.288-1.53C6.084 9.343 5.5 8.193 5.5 7zm11 0c0 1.794-.837 3.486-2.356 4.765a.999.999 0 11-1.288-1.53C13.916 9.343 14.5 8.193 14.5 7s-.584-2.343-1.644-3.235a1 1 0 011.288-1.53C15.663 3.515 16.5 5.206 16.5 7zM10 8a1 1 0 110-2 1 1 0 010 2zM7 7c0-1.654 1.346-3 3-3s3 1.346 3 3a2.993 2.993 0 01-2.035 2.826c.004.021.01.041.015.061.01.037.02.073.02.113v6a1 1 0 11-2 0v-6c0-.04.01-.076.02-.113.006-.02.011-.04.015-.061A2.993 2.993 0 017 7z"
            clipRule="evenodd"
        />
    )
});
