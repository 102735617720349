import * as React from 'react';
import { useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { Box, Spacer } from '@chakra-ui/react';
import { isEmpty, isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { makeSelectDeliveryStatus, makeSelectDeliveryInfo } from 'slices/orders/selectors';
import { CellProps } from '../../types';
import { ElapsedTime } from '../../../../../components/ElapsedTime';
import { Icon } from 'components/Icon';
import { DeliveryStatus } from 'components/DeliveryStatus';
import { InfoPopover } from 'components/InfoPopover';
import { DeliveryMenuBody } from '../../../../../components/MenuPopover/DeliveryMenuBody';
import { MenuPopover } from '../../../../../components/MenuPopover';
import { DeliveryStatus as DeliveryStatusEnum } from '../../../../../types';
import { DELIVERY_STATUSES_LEGEND } from './consts';
import { formatTime } from '../utils';

export const Delivery = ({ orderId }: CellProps) => {
    const selectDeliveryInfo = useMemo(() => makeSelectDeliveryInfo(orderId), [orderId]);
    const selectDeliveryStatus = useMemo(() => makeSelectDeliveryStatus(orderId), [orderId]);

    const deliveryStatus = useSelector(selectDeliveryStatus);
    const {
        driver,
        delivery_time,
        tracking_url,
        note_for_driver,
        stuartCancellation,
        estimated_delivery_time,
        estimated_pickup_time,
        pickup_started,
        delivery_started,
        delivered
    } = useSelector(selectDeliveryInfo);

    const hasDriver = !isNil(driver);
    const menuBody = hasDriver && (
        <DeliveryMenuBody driverName={driver.name} driverPhone={driver.phone} trackingUrl={tracking_url} />
    );

    const displayPickupEta = !delivered && pickup_started && !isNil(estimated_pickup_time);
    const displayDeliveryEta = !delivered && delivery_started && !isNil(estimated_delivery_time);

    return (
        <Flex alignItems="center" position="relative" cursor="default">
            <Flex justify="start" cursor={hasDriver ? 'pointer' : 'default'}>
                <InfoPopover maxWidth="popover.maxWidth.infoLegend" body={DELIVERY_STATUSES_LEGEND}>
                    <Box>
                        {stuartCancellation && <DeliveryStatus status={DeliveryStatusEnum.cancelled} />}
                        <DeliveryStatus status={deliveryStatus} />
                    </Box>
                </InfoPopover>
                <MenuPopover body={menuBody}>
                    <Box ml={1}>
                        <Text isTruncated>{hasDriver && driver.name}</Text>
                    </Box>
                </MenuPopover>
            </Flex>
            <Spacer />
            {displayPickupEta && (
                <Text fontSize="sm" mr={2} color="fonts.secondary">
                    ETA: {formatTime(estimated_pickup_time)}
                </Text>
            )}
            {displayDeliveryEta && (
                <Text fontSize="sm" mr={2} color="fonts.secondary">
                    ETA: {formatTime(estimated_delivery_time)}
                </Text>
            )}
            <Flex justify="end">
                {!isEmpty(note_for_driver) && (
                    <InfoPopover body={note_for_driver}>
                        <Box cursor="pointer" mr={1}>
                            <Icon name="NotesIcon" size={5} />
                        </Box>
                    </InfoPopover>
                )}
                <ElapsedTime>{delivery_time}</ElapsedTime>
            </Flex>
        </Flex>
    );
};
