import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CheckIcon = createIcon({
    displayName: 'CheckIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M7.70701 9.29287C7.31601 8.90187 6.68401 8.90187 6.29301 9.29287C5.90201 9.68387 5.90201 10.3159 6.29301 10.7069L9.29301 13.7069C9.48101 13.8949 9.73501 13.9999 10 13.9999C10.011 13.9999 10.022 13.9999 10.033 13.9999C10.31 13.9899 10.57 13.8669 10.752 13.6589L17.752 5.65887C18.116 5.24287 18.074 4.61087 17.659 4.24787C17.243 3.88387 16.612 3.92787 16.248 4.34087L9.95101 11.5369L7.70701 9.29287Z"
                fill="currentColor"
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M19 8.974H18.997C18.445 8.975 17.999 9.425 18 9.977C18.006 12.114 17.18 14.125 15.673 15.641C14.167 17.156 12.16 17.994 10.023 18H10C7.87105 18 5.86905 17.174 4.35905 15.673C2.84405 14.166 2.00605 12.16 2.00005 10.023C1.99405 7.886 2.82005 5.874 4.32705 4.359C5.83305 2.844 7.84005 2.006 9.97705 2C10.603 2.01 11.262 2.073 11.883 2.223C12.417 2.351 12.96 2.021 13.089 1.485C13.219 0.948 12.888 0.408 12.352 0.278C11.576 0.092 10.766 0.012 9.97105 0C7.30005 0.008 4.79205 1.055 2.90905 2.949C1.02505 4.843 -0.00795388 7.357 4.61203e-05 10.029C0.00804612 12.7 1.05505 15.208 2.94905 17.091C4.83605 18.968 7.33905 20 10 20H10.029C12.7 19.992 15.208 18.945 17.091 17.051C18.975 15.157 20.008 12.642 20 9.971C19.999 9.42 19.551 8.974 19 8.974Z"
                fill="currentColor"
            />
        </>
    )
});
