import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const MessageWithSquareIcon = createIcon({
    displayName: 'MessageWithSquareIcon',
    viewBox: '0 0 26 20',
    path: (
        <>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 4H23C24.654 4 26 5.346 26 7V17C26 18.654 24.654 20 23 20H9C7.346 20 6 18.654 6 17V7C6 5.346 7.346 4 9 4ZM22.333 6L16 10.75L9.667 6H22.333ZM9 18C8.449 18 8 17.552 8 17V7.25L15.4 12.8C15.578 12.934 15.789 13 16 13C16.211 13 16.422 12.934 16.6 12.8L24 7.25V17C24 17.552 23.551 18 23 18H9Z"
                fill="currentColor"
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M6.729 12H2.667C1.196 12 0 10.804 0 9.333V2.667C0 1.196 1.196 0 2.667 0H9.333C10.804 0 12 1.196 12 2.667V5.5H10V2.667C10 2.299 9.701 2 9.333 2H2.667C2.299 2 2 2.299 2 2.667V9.333C2 9.701 2.299 10 2.667 10H6.729V12Z"
                fill="currentColor"
            />
        </>
    )
});
