import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const RestaurantIcon = createIcon({
    displayName: 'RestaurantIcon',
    viewBox: '0 0 21 30',
    path: (
        <>
            <path
                fill="currentColor"
                d="M10.781 13.835H8.697v3.094h2.113c1.193 0 1.784-.502 1.784-1.527a1.537 1.537 0 00-.088-.649 1.491 1.491 0 00-.348-.547 2.02 2.02 0 00-1.377-.371z"
            />
            <path
                fill="currentColor"
                d="M20.263 4.41c-.363.49-.84.876-1.388 1.12a3.38 3.38 0 01-3.388-.362 3.587 3.587 0 01-1.138-1.39.407.407 0 00-.146-.164.387.387 0 00-.415 0 .406.406 0 00-.147.164 3.577 3.577 0 01-1.287 1.495 3.4 3.4 0 01-1.859.555 3.4 3.4 0 01-1.858-.555 3.577 3.577 0 01-1.288-1.495.397.397 0 00-.143-.166.378.378 0 00-.412 0 .397.397 0 00-.143.166 3.596 3.596 0 01-1.141 1.39 3.39 3.39 0 01-3.391.362A3.52 3.52 0 01.726 4.41a.4.4 0 00-.197-.158.384.384 0 00-.25-.005.398.398 0 00-.202.15.422.422 0 00-.078.245v22.213c0 .834.32 1.634.889 2.224.569.59 1.34.921 2.146.921h14.92c.806 0 1.577-.331 2.147-.921.569-.59.888-1.39.888-2.224V4.642a.422.422 0 00-.077-.246.398.398 0 00-.204-.149.384.384 0 00-.249.005.4.4 0 00-.197.159zM12.76 24.274l-2.695-4.722H8.697v4.722H5.71v-13.06h5.1c3.231 0 4.847 1.396 4.847 4.189a3.707 3.707 0 01-.585 2.386c-.459.7-1.14 1.21-1.926 1.442l3.005 5.023-3.393.02z"
            />
            <path
                fill="currentColor"
                d="M3.5 4.46c.579 0 1.133-.237 1.543-.661.409-.424.638-1 .638-1.599V0H1.32v2.2c0 .6.23 1.175.638 1.599.41.424.964.662 1.543.662zM10.5 4.46c.579 0 1.133-.237 1.543-.661.409-.424.638-1 .638-1.599V0H8.32v2.2c0 .6.23 1.175.638 1.599.41.424.964.662 1.543.662zM17.5 4.46c.286 0 .57-.058.833-.171a2.17 2.17 0 00.706-.49c.202-.21.362-.46.47-.734a2.32 2.32 0 00.163-.865V0h-4.353v2.2c0 .6.23 1.175.638 1.599.41.424.965.662 1.543.662z"
            />
        </>
    )
});
