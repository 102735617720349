import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SearchIcon = createIcon({
    displayName: 'SearchIcon',
    viewBox: '0 0 18 18',
    path: (
        <path
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.312 12.897L17.707 16.293C18.098 16.684 18.098 17.316 17.707 17.707C17.512 17.902 17.256 18 17 18C16.744 18 16.488 17.902 16.293 17.707L12.897 14.312C11.543 15.365 9.846 16 8 16C3.589 16 0 12.411 0 8C0 3.589 3.589 0 8 0C12.411 0 16 3.589 16 8C16 9.846 15.365 11.543 14.312 12.897ZM8 2C4.691 2 2 4.691 2 8C2 11.309 4.691 14 8 14C11.309 14 14 11.309 14 8C14 4.691 11.309 2 8 2Z"
            fill="currentColor"
        />
    )
});
