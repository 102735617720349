import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { formatPrice } from '../../../../utils';
import { Separator } from '../../../../components/Separator';
import { Refund } from './Refund';

interface RefundsProps {
    order: any;
}

export const Refunds = ({ order }: RefundsProps) => {
    const { total_refund_amount, refunds } = order ?? {};

    return (
        <>
            {!isNil(total_refund_amount) && (
                <>
                    <HStack w="full" justifyContent="flex-start" py={1} pl={4} pr={10} alignItems="center">
                        <Stack direction="row">
                            <Text>Customer was refunded:</Text>
                            <Text fontWeight="bold">{formatPrice(total_refund_amount)}</Text>
                        </Stack>
                    </HStack>
                    <Separator />
                </>
            )}

            {!isNil(refunds) && !isEmpty(refunds) && (
                <>
                    <VStack w="full" justifyContent="flex-start" py={1} pl={4} pr={10} alignItems="flex-start">
                        {refunds.map((refund: any) => (
                            <Refund key={refund.id} refund={refund} />
                        ))}
                    </VStack>
                    <Separator />
                </>
            )}
        </>
    );
};
