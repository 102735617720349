import { cssVar } from '@chakra-ui/react';
const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

export const Switch = {
    baseStyle: {
        track: {
            bg: 'red.300',
            p: 1 / 2,
            _checked: {
                bg: 'green.500'
            },
            _focus: {
                boxShadow: 'none'
            }
        },
        thumb: {
            padding: 1,
            backgroundColor: 'white',
            backgroundImage: 'url("/x.svg")',
            backgroundSize: '24px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundOrigin: 'content-box',
            _checked: {
                padding: 1,
                backgroundColor: 'white',
                backgroundImage: 'none',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundOrigin: 'content-box',
                w: '24px',
                h: '24px'
            }
        }
    },
    sizes: {
        md: {
            container: {
                h: '28px',
                [$width.variable]: '39px',
                [$height.variable]: '24px'
            }
        }
    }
};
