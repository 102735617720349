import React from 'react';
import { Separator } from '../../../components/Separator';
import { HStack, Text } from '@chakra-ui/react';
import { CallButton, CallButtonVariants } from '../../../components/CallButton';
import { isNil } from 'ramda';

interface DriverDetailsProps {
    order: any;
}

export const DriverDetails = ({ order }: DriverDetailsProps) => {
    if (isNil(order?.driver)) {
        return <></>;
    }

    const { name = '', phone = '' } = order?.driver ?? {};
    return (
        <>
            <Separator label="DRIVER" />
            <HStack w="full" justifyContent="space-between" py={0} pl={2} alignItems="center">
                <Text fontWeight="normal">{name}</Text>
                <CallButton phone={phone} variant={CallButtonVariants.default} />
            </HStack>
        </>
    );
};
