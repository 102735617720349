import React from 'react';
import { HStack, StackProps } from '@chakra-ui/layout';
import { Divider, Text } from '@chakra-ui/react';
import { isNil } from 'ramda';

type SeparatorProps = {
    label?: string;
    hasOpacity?: boolean;
};

export const Separator = ({ label, hasOpacity, ...props }: SeparatorProps & StackProps) => {
    const variant = hasOpacity ? 'with-opacity' : undefined;

    if (isNil(label)) {
        return <Divider variant={variant} minHeight="1px" />;
    }

    return (
        <HStack w="full" minHeight={1} {...props}>
            <Divider variant={variant} minHeight="1px" />
            <Text fontSize="sm" opacity="42%" casing="uppercase">
                {label}
            </Text>
        </HStack>
    );
};

Separator.defaultProps = {
    label: undefined,
    hasOpacity: false
};
