import React from 'react';
import { VStack, Button } from '@chakra-ui/react';
import { useCallToConfirmOrderMutation, useCallToTurnTabletOnMutation } from 'api/service';

export interface RestaurantMenuBodyProps {
    restaurantId: string;
    orderId: string;
    isDeviceAvailable: boolean;
}

export const RestaurantMenuBody = ({ restaurantId, orderId, isDeviceAvailable }: RestaurantMenuBodyProps) => {
    const [callToTurnTabletOn, { isLoading: isCallingToTurnTablet }] = useCallToTurnTabletOnMutation();
    const [callToConfirmOrder, { isLoading: isCallingToConfirmOrder }] = useCallToConfirmOrderMutation();

    return (
        <VStack w="full">
            <Button
                variant="menu"
                disabled={isCallingToTurnTablet}
                isFullWidth
                onClick={() => callToTurnTabletOn({ restaurantId, orderId })}
            >
                Call to turn tablet on
            </Button>
            {isDeviceAvailable && (
                <Button
                    variant="menu"
                    disabled={isCallingToConfirmOrder}
                    isFullWidth
                    onClick={() => callToConfirmOrder({ restaurantId, orderId })}
                >
                    Call to confirm order
                </Button>
            )}
        </VStack>
    );
};
