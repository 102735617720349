import { createStandaloneToast } from '@chakra-ui/toast';
import { theme } from '../theme';

export const createSuccessToast = (title: string): void => {
    const toast = createStandaloneToast({
        theme
    });

    toast({
        title,
        status: 'success',
        variant: 'subtle',
        position: 'bottom-left',
        duration: 5000,
        isClosable: true
    });
};
