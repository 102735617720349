import { HStack, Text } from '@chakra-ui/react';
import { StackProps } from '@chakra-ui/layout';
import React from 'react';

export interface ExpenseProps {
    item: string;
    cost: string;
}
export const Expense = ({ item, cost, ...props }: ExpenseProps & StackProps) => (
    <HStack {...props}>
        <Text>{item}:</Text>
        <Text fontWeight="bold">{cost}</Text>
    </HStack>
);
