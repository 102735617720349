import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const RefundIcon = createIcon({
    displayName: 'RefundIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 10A5 5 0 1 0 2.297.793a.498.498 0 0 0-.26.117.365.365 0 0 0-.095.134A5 5 0 0 0 5 10ZM1.942 1.044a.681.681 0 0 0-.047.269v7.458c0 .043.013.08.038.115.025.034.07.061.133.082.064.021.15.038.257.051.108.013.243.02.404.02.165 0 .3-.007.406-.02.106-.013.19-.03.254-.05a.29.29 0 0 0 .136-.083.176.176 0 0 0 .041-.115V5.642h.521c.178 0 .334.028.47.083.135.055.255.136.358.244.104.108.198.242.283.403.084.16.171.345.26.552l.736 1.835c.017.05.038.094.064.13a.272.272 0 0 0 .13.089.94.94 0 0 0 .25.047c.107.009.248.013.426.013.212 0 .379-.004.501-.013.123-.008.216-.023.28-.044.063-.021.103-.048.12-.08a.247.247 0 0 0 .026-.117.68.68 0 0 0-.032-.174 4.333 4.333 0 0 0-.127-.353L7.138 6.64a5.316 5.316 0 0 0-.247-.524 2.96 2.96 0 0 0-.27-.413 1.827 1.827 0 0 0-.65-.523c.253-.08.48-.186.678-.318a1.928 1.928 0 0 0 .81-1.07c.07-.226.104-.476.104-.751 0-.326-.048-.615-.146-.867a1.862 1.862 0 0 0-.422-.654 2.138 2.138 0 0 0-.672-.447A3.46 3.46 0 0 0 5.43.83a7.68 7.68 0 0 0-.914-.045H2.39a.64.64 0 0 0-.093.007c-.122.078-.24.162-.355.25Zm2.473 3.348h-.85v-2.33h.748c.19 0 .34.006.448.02.108.012.204.029.289.05.28.076.48.207.603.393.123.187.184.415.184.686 0 .178-.03.338-.089.482a.987.987 0 0 1-.267.372 1.242 1.242 0 0 1-.444.24 2.037 2.037 0 0 1-.622.087Z"
            fill="#091D46"
        />
    )
});
