import * as React from 'react';
import { Text } from '@chakra-ui/layout';
import { Center } from '@chakra-ui/react';
import { CellProps } from '../../types';
import { useMemo } from 'react';
import { makeSelectCreationDate } from '../../../../../slices/orders/selectors';
import { useSelector } from 'react-redux';
import { formatDate, formatTime } from '../utils';

export const CreatedTime = ({ orderId }: CellProps) => {
    const selectCreationDate = useMemo(() => makeSelectCreationDate(orderId), [orderId]);
    const creationDate = useSelector(selectCreationDate);

    const time = formatTime(creationDate);
    const date = formatDate(creationDate);

    return (
        <Center>
            <Text fontFamily="monospace" mr={1}>
                {date}
            </Text>

            <Text fontFamily="monospace" casing="uppercase" fontSize="xl" fontWeight="bold">
                {time}
            </Text>
        </Center>
    );
};
