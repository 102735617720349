import { Dispatch } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import type { RootState, AppDispatch } from './store';
import { useMediaQuery } from '@chakra-ui/react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): Dispatch<AnyAction> => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useIsMobileView = () => {
    const [isMobileView] = useMediaQuery('(max-width: 430px)');
    return isMobileView;
};
