import { createSelector } from '@reduxjs/toolkit';
import { AuthState } from '.';

export interface AuthRootState {
    auth: AuthState;
}

const authSelector = (state: AuthRootState): AuthState => state.auth;

export const signedInSelector = createSelector(authSelector, (s) => !!s.userId);

export const userIdSelector = createSelector(authSelector, (s) => s.userId);

export const emailSelector = createSelector(authSelector, (s) => s.email);

export const displayNameSelector = createSelector(authSelector, emailSelector, (s, email) => s.displayName ?? email);

export const loadingSelector = createSelector(authSelector, (s) => s.loading);
