import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text } from '@chakra-ui/layout';
import { Center } from '@chakra-ui/react';
import { InfoPopover } from '../../../../../components/InfoPopover';
import { makeSelectHotel } from '../../../../../slices/orders/selectors';
import { CellProps } from '../../types';
import { isNil } from 'ramda';
import { Hotel as HotelType, Manager } from '../../../../../slices/orders/types';

export const Hotel = ({ orderId }: CellProps) => {
    const selectHotel = useMemo(() => makeSelectHotel(orderId), [orderId]);
    const hotel: HotelType | undefined = useSelector(selectHotel);

    if (isNil(hotel)) {
        return null;
    }

    const { main_code, name, address, upfront_payment, manager } = hotel;
    const { name: managerName, email, phone }: Manager = manager;

    const hotelName = <Box textAlign="center">{name}</Box>;
    const fullAddress = (
        <Box textAlign="center">
            {main_code}
            <br />
            {address}
        </Box>
    );
    const managerContact = (
        <Box textAlign="center">
            {managerName}
            <br />
            {email}
            <br />
            {phone}
        </Box>
    );
    const paymentStatus = !upfront_payment && <Box textAlign="center">Reception payment!</Box>;

    return (
        <Center>
            <InfoPopover body={[hotelName, fullAddress, managerContact, paymentStatus]}>
                <Text casing="uppercase" cursor="pointer">
                    {main_code}
                </Text>
            </InfoPopover>
        </Center>
    );
};
