import React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import { VStack, HStack, IconButton } from '@chakra-ui/react';
import { Icon } from '../Icon';
import { InfoPopover } from '../InfoPopover';
import { TextMenuOption } from './components/TextMenuOption';

export interface DeliveryMenuBodyProps {
    driverName: string;
    driverPhone: string;
    trackingUrl: string;
}

export const DeliveryMenuBody = ({ driverName, driverPhone, trackingUrl }: DeliveryMenuBodyProps) => {
    return (
        <VStack w="full">
            <HStack m={0} w="full">
                <TextMenuOption>{driverName}</TextMenuOption>

                <InfoPopover body="Copy phone number to clipboard." placement="right">
                    <IconButton
                        onClick={() => copyToClipboard(driverPhone)}
                        variant="menu"
                        justifyContent="center"
                        icon={<Icon name="PhoneWithSquareIcon" />}
                        aria-label=""
                    />
                </InfoPopover>
            </HStack>

            <HStack m={0} w="full">
                <TextMenuOption>Tracking Link:</TextMenuOption>

                <InfoPopover body="Copy tracking link to clipboard." placement="right">
                    <IconButton
                        onClick={() => copyToClipboard(trackingUrl)}
                        variant="menu"
                        justifyContent="center"
                        icon={<Icon name="PinPointWithSquareIcon" />}
                        aria-label=""
                    />
                </InfoPopover>
            </HStack>
        </VStack>
    );
};
