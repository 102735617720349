import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ThreeDotsIcon = createIcon({
    displayName: 'ThreeDotsIcon',
    viewBox: '0 0 18 4',
    path: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0 2a2 2 0 114.001.001A2 2 0 010 2zm9-2a2 2 0 10.001 4.001A2 2 0 009 0zm7 0a2 2 0 10.001 4.001A2 2 0 0016 0z"
            clipRule="evenodd"
        />
    )
});
