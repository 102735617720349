import React from 'react';
import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { isNil } from 'ramda';
import { OrderCardQuickActions, OrderCardQuickActionsType } from '../OrderCardQuickActions';
import { Separator } from '../../components/Separator';
import { CallButton, CallButtonVariants } from '../../components/CallButton';

interface BodyDefaultProps {
    order: any;
}

export const DefaultBody = ({ order }: BodyDefaultProps) => {
    const { first_name, last_name, phone } = order.user;
    return (
        <VStack w="full" spacing={0} px={1} alignItems="center">
            <OrderCardQuickActions order={order} py={3} pl={4} pr={2} type={OrderCardQuickActionsType.default} />
            <Separator />
            <HStack w="full" justifyContent="space-between" py={3} pl={4} pr={2} alignItems="center">
                <Stack direction="row" alignItems="center">
                    {!isNil(first_name) && !isNil(last_name) && (
                        <Text fontWeight="normal">{`${first_name} ${last_name}`}</Text>
                    )}
                    {!isNil(order.hotel) && !isNil(order.hotel.main_code) && (
                        <Text fontWeight="bold">{order.hotel.main_code}</Text>
                    )}
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                    <Box px={0}>
                        <CallButton phone={phone} variant={CallButtonVariants.default} />
                    </Box>
                </Stack>
            </HStack>
        </VStack>
    );
};
