import React from 'react';
import { values } from 'ramda';
import { DEVICE_STATUSES_INFO } from './utils';
import { Flex, Text } from '@chakra-ui/react';
import { Icon } from '../../components/Icon';

export const DEVICE_STATUSES_LEGEND = values(DEVICE_STATUSES_INFO).map(({ color, info }) => (
    <Flex align="center" key={color}>
        <Icon name="SignalIcon" color={color} size={5} />
        <Text ml={2} variant="infoPopover">
            {info}
        </Text>
    </Flex>
));
