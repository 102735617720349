import { injectEndpoints } from '../../api/service';
import { fromPairs } from 'ramda';

export const pendingOrdersApi = injectEndpoints({
    endpoints: (builder) => ({
        getPendingOrders: builder.query<any, void>({
            query: () => ({
                url: '/orders_list?per=5&q[s]=created_at+desc&q[paid_not_true]=1',
                method: 'get'
            }),
            transformResponse: (response: any) => {
                return fromPairs(response.map((order: any) => [order.id, order]));
            }
        })
    })
});

export const useGetPendingOrdersQuery = pendingOrdersApi.useGetPendingOrdersQuery;
