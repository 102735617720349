import React, { useState } from 'react';
import { Button, FormControl, Input, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { isNil, isEmpty } from 'ramda';
import { signIn } from '../api';
import { isUserAuthenticated } from '../../../api/auth';
import { Redirect } from 'react-router-dom';

export const LoginForm = () => {
    const [authError, setAuthError] = useState('');

    const clearAuthError = () => setAuthError('');

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting }
    } = useForm({
        defaultValues: {
            username: '',
            password: ''
        }
    });

    const onSubmit = async ({ username, password }: any) => {
        try {
            await signIn(username, password);
        } catch (error: any) {
            if (error.response?.status === 401) {
                setAuthError('Wrong username or password. Please try again.');
            } else {
                setAuthError('An unexpected error occurred. Please try again.');
            }
        }
    };

    if (isUserAuthenticated()) {
        return <Redirect to="/" />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
                <Input
                    placeholder="Username (email)"
                    isInvalid={!isNil(errors.username) || !isEmpty(authError)}
                    onFocus={clearAuthError}
                    {...register('username', {
                        required: 'Username is required'
                    })}
                />
                <Text pl={1} variant="alert">
                    {errors.username && errors.username.message}
                </Text>

                <Input
                    mt={4}
                    type="password"
                    placeholder="Password"
                    isInvalid={!isNil(errors.username) || !isEmpty(authError)}
                    onFocus={clearAuthError}
                    {...register('password', {
                        required: 'Password is required'
                    })}
                />
                <Text pl={1} variant="alert">
                    {errors.password && errors.password.message}
                </Text>
            </FormControl>

            <Text pl={1} variant="alert">
                {authError}
            </Text>

            <Button mt={4} type="submit" variant="login" isLoading={isSubmitting} isFullWidth>
                Login
            </Button>
        </form>
    );
};
