import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { Separator } from '../../../components/Separator';
import { isEmpty, isNil } from 'ramda';

interface LogDetailsProps {
    logs?: string[];
}

export const LogDetails = ({ logs }: LogDetailsProps) =>
    isNil(logs) || isEmpty(logs) ? (
        <React.Fragment />
    ) : (
        <>
            <Separator label="LOG" />
            <VStack w="full" justifyContent="flex-start" pb={2} pl={4} alignItems="flex-start">
                <Text>
                    {logs.map((log) => (
                        <>
                            {log}
                            <br />
                        </>
                    ))}
                </Text>
            </VStack>
        </>
    );
