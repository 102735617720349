import * as React from 'react';
import { TextProps } from '@chakra-ui/layout';
import { Text, Spacer } from '@chakra-ui/layout';
import { isNil } from 'ramda';
import { getElapsedTimeColor } from './utils';

type ElapsedTimeProps = {
    children: number;
    size?: TextProps['size'];
};

export const ElapsedTime = ({ children, size = 'xl', ...props }: ElapsedTimeProps & TextProps) => {
    const color = getElapsedTimeColor(children);
    return (
        <>
            {isNil(children) ? (
                <Spacer />
            ) : (
                <Text fontSize={size} color={color} fontWeight="bold" {...props}>
                    {`${children}\'`}
                </Text>
            )}
        </>
    );
};
