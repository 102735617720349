import { createSlice } from '@reduxjs/toolkit';
import { pendingOrdersApi } from './api';
import { keys } from 'ramda';

export interface PendingOrdersState {
    liveOrdersIds: any[];
    pendingOrdersById: any;
}

const initialState: PendingOrdersState = {
    liveOrdersIds: [],
    pendingOrdersById: {}
};

const matchGetPendingOrdersSuccess = pendingOrdersApi.endpoints.getPendingOrders.matchFulfilled;

const pendingOrderSlice = createSlice({
    name: 'pendingOrders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(matchGetPendingOrdersSuccess, (state, { payload }) => {
            state.liveOrdersIds = keys(payload);
            state.pendingOrdersById = payload;
        });
    }
});

export const pendingOrdersReducer = pendingOrderSlice.reducer;
