import React from 'react';
import { Separator } from '../../../components/Separator';
import { Box, Text, VStack } from '@chakra-ui/react';
import { isEmpty, isNil } from 'ramda';

interface NotesDetailsProps {
    order: any;
}

export const NotesDetails = ({ order }: NotesDetailsProps) => {
    const { note_for_driver, note_for_restaurant } = order?.address ?? {};

    const isDriverNote = !isNil(note_for_driver) && !isEmpty(note_for_driver);
    const isRestaurantNote = !isNil(note_for_restaurant) && !isEmpty(note_for_restaurant);

    return (
        <>
            {(isDriverNote || isRestaurantNote) && <Separator label="NOTES" />}
            {isRestaurantNote && (
                <VStack w="full" alignItems="flex-start" px={3} pt={4}>
                    <Text fontSize="sm" opacity="42%" casing="uppercase">
                        customer to restaurant
                    </Text>
                    <Box bgColor="grey2" p={2} borderRadius={5} w="full">
                        <Text fontSize="lg" textColor="fonts.secondary">
                            {note_for_restaurant}
                        </Text>
                    </Box>
                </VStack>
            )}

            {isDriverNote && (
                <VStack w="full" alignItems="flex-start" px={3} pt={4} pb={2}>
                    <Text fontSize="sm" opacity="42%" casing="uppercase">
                        customer to driver
                    </Text>
                    <Box bgColor="grey2" w="full" p={2} borderRadius={5}>
                        <Text fontSize="lg" textColor="fonts.secondary">
                            {note_for_driver}
                        </Text>
                    </Box>
                </VStack>
            )}
        </>
    );
};
