import React from 'react';
import { VStack } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { DeliveryStatus } from '../../types';
import { Icon } from '../Icon';

export interface CardProps {
    header?: React.ReactNode;
    body?: React.ReactNode;
    deliveryStatus: DeliveryStatus;
    cancelled: boolean;
}

export const Card = ({ header, body, deliveryStatus, cancelled = false }: CardProps) => (
    <Box
        w="full"
        bg="white"
        boxShadow="cardContainer"
        borderRadius="card.container"
        pl="card.pl"
        pt="card.pt"
        position="relative"
    >
        {cancelled && (
            <Box position="absolute" top={-3} left="50%" transform="translate3d(-50%, 0 ,0)" zIndex="docked">
                <Icon name="CancelledIcon" size={10} />
            </Box>
        )}

        <VStack
            w="full"
            borderRadius="card.container"
            borderLeftWidth="card.deliveryStatus"
            borderColor={`deliveryStatus.${deliveryStatus}`}
            overflow="hidden"
            opacity={cancelled ? 0.5 : 1}
        >
            <Box bg="grey2" w="full" minH={74} overflow="hidden">
                {header}
            </Box>
            <Box w="full" minH={121} m={0}>
                {body}
            </Box>
        </VStack>
    </Box>
);
