import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { RestaurantDeviceStatus } from './types';
import { makeSelectDeviceStatus } from './selectors';
import { InfoPopover } from '../../components/InfoPopover';
import { Icon } from '../../components/Icon';
import { DEVICE_STATUSES_INFO } from './utils';
import { useIsMobileView } from '../../hooks';
import { DEVICE_STATUSES_LEGEND } from './const';

export interface DeviceLiveStatusProps {
    restaurantId: string;
}

export const DeviceLiveStatus = ({ restaurantId }: DeviceLiveStatusProps) => {
    const isMobileView = useIsMobileView();

    const selectDeviceLiveStatus = useMemo(() => makeSelectDeviceStatus(restaurantId), [restaurantId]);
    const deviceStatus: RestaurantDeviceStatus = useSelector(selectDeviceLiveStatus);

    const { color } = DEVICE_STATUSES_INFO[deviceStatus];

    return (
        <InfoPopover
            maxWidth="popover.maxWidth.infoLegend"
            body={DEVICE_STATUSES_LEGEND}
            kind={isMobileView ? 'mobile' : 'desktop'}
        >
            <Flex cursor="pointer">
                <Icon name="SignalIcon" color={color} size={5} />
            </Flex>
        </InfoPopover>
    );
};
