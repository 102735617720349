export const getElapsedTimeColor = (minutes: number) => {
    if (minutes > 45) {
        return 'red.300';
    }
    if (minutes > 35) {
        return 'yellowScheme.300';
    }
    if (minutes > 25) {
        return 'blueScheme.200';
    }
    return 'green.500';
};
