import * as React from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { Center } from '@chakra-ui/react';
import { CellProps } from '../../types';
import { InfoPopover } from '../../../../../components/InfoPopover';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { defaultTo } from 'ramda';
import { makeSelectPendingAddressInfo } from '../../../../../slices/pendingOrders/selectors';

export const Address = ({ orderId }: CellProps) => {
    const selectAddressInfo = useMemo(() => makeSelectPendingAddressInfo(orderId), [orderId]);
    const { postcode, first_line, second_line, room_number } = useSelector(selectAddressInfo);

    const details = (
        <Box textAlign="center">
            {first_line} {defaultTo('', second_line)}
        </Box>
    );
    const roomNumber = room_number && <Box textAlign="center">Room {room_number}</Box>;

    return (
        <Center>
            <InfoPopover body={[details, roomNumber]}>
                <Box cursor="pointer">
                    <Text casing="uppercase" fontWeight="bold">
                        {postcode}
                    </Text>
                </Box>
            </InfoPopover>
        </Center>
    );
};
