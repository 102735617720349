import { combineEpics, Epic } from 'redux-observable';
import { filter, switchMap } from 'rxjs';
import {
    createUserWithEmailAndPassword,
    createUserWithEmailAndPasswordError,
    createUserWithEmailAndPasswordSuccess,
    firebaseCreateAccountWithEmailAndPassword,
    firebaseSignInWithEmailAndPassword,
    firebaseSignInWithGooglePopup,
    firebaseSignOut,
    signInWithEmailAndPassword,
    signInWithEmailAndPasswordError,
    signInWithEmailAndPasswordSuccess,
    signInWithGooglePopup,
    signInWithGooglePopupError,
    signInWithGooglePopupSuccess,
    signOut,
    signOutError,
    signOutSuccess
} from '.';

const epics: Record<string, Epic> = {
    signInWithEmailAndPasswordEpic: (action$) =>
        action$.pipe(
            filter(signInWithEmailAndPassword.match),
            switchMap(({ payload }) =>
                firebaseSignInWithEmailAndPassword(payload.email, payload.password)
                    .then(signInWithEmailAndPasswordSuccess)
                    .catch(signInWithEmailAndPasswordError)
            )
        ),
    signInWithGooglePopupEpic: (action$) =>
        action$.pipe(
            filter(signInWithGooglePopup.match),
            switchMap(() =>
                firebaseSignInWithGooglePopup().then(signInWithGooglePopupSuccess).catch(signInWithGooglePopupError)
            )
        ),
    createUserWithEmailAndPasswordEpic: (action$) =>
        action$.pipe(
            filter(createUserWithEmailAndPassword.match),
            switchMap(({ payload }) =>
                firebaseCreateAccountWithEmailAndPassword(payload.email, payload.password)
                    .then(createUserWithEmailAndPasswordSuccess)
                    .catch(createUserWithEmailAndPasswordError)
            )
        ),
    signOutEpic: (action$) =>
        action$.pipe(
            filter(signOut.match),
            switchMap(() => firebaseSignOut().then(signOutSuccess).catch(signOutError))
        )
};

export const firebaseAuthEpic: Epic = combineEpics(...Object.values(epics));
