import * as React from 'react';
import { OrderTableRow } from '../../../../components/OrderTable/OrderTableRow';
import {
    Hotel,
    LinkId,
    Price,
    Delivery,
    CreatedTime,
    Address,
    Customer,
    Restaurant,
    OrderStatus,
    OrderType
} from './cells';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectIsRowHighlighted } from '../../../../slices/orders/selectors';

type OrderRowProps = {
    orderId: string;
};

export const OrderRow = ({ orderId }: OrderRowProps) => {
    const selectIsHighlightedRow = useMemo(() => makeSelectIsRowHighlighted(orderId), [orderId]);
    const isHighlightedRow = useSelector(selectIsHighlightedRow);

    return (
        <OrderTableRow isHighlightedRow={isHighlightedRow}>
            <LinkId orderId={orderId} />
            <Restaurant orderId={orderId} />
            <Hotel orderId={orderId} />
            <Customer orderId={orderId} />
            <OrderType orderId={orderId} />
            <OrderStatus orderId={orderId} />
            <Delivery orderId={orderId} />
            <Price orderId={orderId} />
            <Address orderId={orderId} />
            <CreatedTime orderId={orderId} />
        </OrderTableRow>
    );
};
