import { Link } from '@chakra-ui/layout';
import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CellProps } from '../../types';
import { makeSelectPendingRestaurant } from '../../../../../slices/pendingOrders/selectors';

export const Restaurant = ({ orderId }: CellProps) => {
    const selectRestaurant = useMemo(() => makeSelectPendingRestaurant(orderId), [orderId]);

    const { restaurantId, name } = useSelector(selectRestaurant);

    return (
        <Flex align="center" flexDirection="row" gridGap={2}>
            <Link href={`${process.env.REACT_APP_HOST_URL}/admin/restaurants/${restaurantId}`} target="_blank">
                {name}
            </Link>
        </Flex>
    );
};
