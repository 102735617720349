import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PhoneDotIcon = createIcon({
    displayName: 'PhoneDotIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M0 5.37C0 13.437 6.563 20 14.63 20a4.375 4.375 0 004.133-5.787.948.948 0 00-.684-.614l-5.666-1.3a.959.959 0 00-.87.24c-.135.129-.14.133-.757 1.312a9.451 9.451 0 01-4.635-4.64c1.178-.616 1.181-.619 1.31-.754a.948.948 0 00.24-.87l-1.3-5.665a.952.952 0 00-.615-.686 4.133 4.133 0 00-.688-.174A4.374 4.374 0 000 5.37zm2 .067A2.44 2.44 0 014.745 3.02l.994 4.332-.504.263c-.808.422-1.445.756-1.11 1.492 1.1 3.137 3.631 5.668 6.73 6.756.789.317 1.099-.274 1.525-1.088l.002-.003.177-.338.09-.173 4.331.994A2.44 2.44 0 0114.562 18C7.636 18 2 12.364 2 5.437z"
                clipRule="evenodd"
            />
            <circle cx="15" cy="5" r="5" fill="#FF3312" />
        </>
    )
});
