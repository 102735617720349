import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PhoneWithSquareIcon = createIcon({
    displayName: 'PhoneWithSquareIcon',
    viewBox: '0 0 26 25',
    path: (
        <>
            <path
                fill="currentColor"
                d="M6.729 12H2.667A2.67 2.67 0 010 9.333V2.667A2.67 2.67 0 012.667 0h6.666A2.67 2.67 0 0112 2.667v3.734h-2V2.667A.667.667 0 009.333 2H2.667A.667.667 0 002 2.667v6.666c0 .368.299.667.667.667h4.062v2z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M6 9.6C6 18.092 12.908 25 21.4 25a4.605 4.605 0 004.351-6.092.998.998 0 00-.72-.646l-5.965-1.369a1.01 1.01 0 00-.916.253c-.142.136-.146.14-.796 1.381a9.949 9.949 0 01-4.879-4.883c1.24-.649 1.243-.652 1.379-.794a.997.997 0 00.253-.916L12.738 5.97a1.002 1.002 0 00-.647-.722 4.35 4.35 0 00-.725-.183A4.605 4.605 0 006 9.6zm2 0a2.602 2.602 0 012.928-2.579l1.06 4.621-.537.281c-.862.45-1.542.806-1.185 1.591 1.175 3.346 3.874 6.046 7.18 7.206.841.339 1.171-.291 1.626-1.16l.002-.003.188-.36.097-.185 4.62 1.06A2.603 2.603 0 0121.4 23C14.011 23 8 16.988 8 9.6z"
                clipRule="evenodd"
            />
            <mask style={{ maskType: 'alpha' }} width="20" height="20" x="6" y="5" maskUnits="userSpaceOnUse">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M6 9.6C6 18.092 12.908 25 21.4 25a4.605 4.605 0 004.351-6.092.998.998 0 00-.72-.646l-5.965-1.369a1.01 1.01 0 00-.916.253c-.142.136-.146.14-.796 1.381a9.949 9.949 0 01-4.879-4.883c1.24-.649 1.243-.652 1.379-.794a.997.997 0 00.253-.916L12.738 5.97a1.002 1.002 0 00-.647-.722 4.35 4.35 0 00-.725-.183A4.605 4.605 0 006 9.6zm2 0a2.602 2.602 0 012.928-2.579l1.06 4.621-.537.281c-.862.45-1.542.806-1.185 1.591 1.175 3.346 3.874 6.046 7.18 7.206.841.339 1.171-.291 1.626-1.16l.002-.003.188-.36.097-.185 4.62 1.06A2.603 2.603 0 0121.4 23C14.011 23 8 16.988 8 9.6z"
                    clipRule="evenodd"
                />
            </mask>
        </>
    )
});
