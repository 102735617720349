import { Flex, Text, Link, VStack } from '@chakra-ui/layout';
import { isNil } from 'ramda';
import * as React from 'react';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectLiveIdInfo } from 'slices/orders/selectors';
import { CellProps } from '../../types';
import { ElapsedTime } from '../../../../../components/ElapsedTime';
import { InfoPopover } from '../../../../../components/InfoPopover';

export const LinkId = ({ orderId }: CellProps) => {
    const selectIdInfo = useMemo(() => makeSelectLiveIdInfo(orderId), [orderId]);

    const {
        human_id,
        elapsed_time,
        belongs_to_order,
        belongs_to_order_human_id,
        extended_by_order,
        extended_by_order_human_id,
        logs
    } = useSelector(selectIdInfo);

    const logsTextComponents = (
        <Text fontSize="smaller" overflow="hidden" whiteSpace="nowrap">
            {logs.map((log: string) => (
                <Fragment key={log}>
                    {log}
                    <br />
                </Fragment>
            ))}
        </Text>
    );
    return (
        <InfoPopover body={logsTextComponents} placement="bottom-start" maxWidth="popover.maxWidth.logs" fullWidth>
            <Flex ml={4} justify="space-between">
                <ElapsedTime alignSelf="end">{elapsed_time}</ElapsedTime>
                <VStack spacing={0}>
                    <Text>
                        <Link href={`${process.env.REACT_APP_HOST_URL}/admin/orders/${orderId}`} target="_blank">
                            {human_id}
                        </Link>
                    </Text>
                    {!isNil(belongs_to_order_human_id) && (
                        <Text fontSize="sm" lineHeight={3}>
                            <Link
                                href={`${process.env.REACT_APP_HOST_URL}/admin/orders/${belongs_to_order}`}
                                target="_blank"
                            >
                                {`+${belongs_to_order_human_id}`}
                            </Link>
                        </Text>
                    )}
                    {!isNil(extended_by_order_human_id) && (
                        <Text fontSize="sm" lineHeight={3}>
                            <Link
                                href={`${process.env.REACT_APP_HOST_URL}/admin/orders/${extended_by_order}`}
                                target="_blank"
                            >
                                {`+${extended_by_order_human_id}`}
                            </Link>
                        </Text>
                    )}
                </VStack>
            </Flex>
        </InfoPopover>
    );
};
