import React, { useEffect, useState } from 'react';
import { hydrateAccessToken } from './config';

export interface ApiConfigProps {
    children: React.ReactNode;
}

export const ApiConfig = ({ children }: ApiConfigProps) => {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        hydrateAccessToken();
        setIsReady(true);
    }, []);

    if (isReady) {
        return <>{children}</>;
    }

    return null;
};
