import * as React from 'react';
import { createIcon } from '@chakra-ui/react';

export const DashIcon = createIcon({
    displayName: 'DashIcon',
    viewBox: '0 0 16 2',
    path: (
        <path
            d="M15 2H1C0.448 2 0 1.553 0 1C0 0.447 0.448 0 1 0H15C15.553 0 16 0.447 16 1C16 1.553 15.553 2 15 2Z"
            fill="currentColor"
        />
    )
});
