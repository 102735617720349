import { Text } from '@chakra-ui/layout';
import * as React from 'react';

type OrderStatusPopoverBodyProps = {
    main?: boolean;
    belonging?: boolean;
    confirmed: boolean;
};

export const OrderStatusPopoverBody = ({ main, belonging, confirmed }: OrderStatusPopoverBodyProps) => (
    <Text variant="infoPopover" textAlign="center">
        {(main && (
            <>
                <b>Main</b> order
            </>
        )) ||
            (belonging && (
                <>
                    <b>Belonging</b> order
                </>
            )) ||
            'Order'}{' '}
        is <b>{!confirmed && 'not '}confirmed</b>.
        <br />
        Click to change confirmation status
    </Text>
);
