export const ID_LINK_COLUMN_ID = 'id-link';
export const RESTAURANT_COLUMN_ID = 'restaurant';
export const HOTEL_COLUMN_ID = 'hotel';
export const CUSTOMER_COLUMN_ID = 'customer';
export const ORDER_TYPE_COLUMN_ID = 'order-type';
export const ORDER_STATUS_COLUMN_ID = 'order-status';
export const DELIVERY_COLUMN_ID = 'delivery';
export const PRICE_COLUMN_ID = 'price';
export const ADDRESS_COLUMN_ID = 'address';
export const CREATED_TIME_COLUMN_ID = 'created-time';

export const PENDING = 'pending';

export const ORDER_TABLE_COLUMNS_CONFIG = [
    {
        id: ID_LINK_COLUMN_ID,
        label: 'Id/Link',
        width: '115px',
        parameter: 'q[human_id_or_id_eq]'
    },
    {
        id: RESTAURANT_COLUMN_ID,
        label: 'restaurant',
        width: 'auto',
        parameter: 'q[restaurant.name_or_restaurant.email_cont]'
    },
    {
        id: HOTEL_COLUMN_ID,
        label: 'Hotel',
        width: '70px'
    },
    {
        id: CUSTOMER_COLUMN_ID,
        label: 'Customer',
        width: 'auto',
        parameter: 'q[user.name_or_user.first_name_or_user.last_name_cont]'
    },
    {
        id: ORDER_TYPE_COLUMN_ID,
        label: 'order type',
        width: '100px',
        parameter: 'q[order_type_cont]'
    },
    {
        id: ORDER_STATUS_COLUMN_ID,
        label: 'Order status',
        width: '200px'
    },
    {
        id: DELIVERY_COLUMN_ID,
        label: 'Delivery',
        width: 'auto',
        parameter: 'q[driver.name_or_driver.type_cont]'
    },
    {
        id: PRICE_COLUMN_ID,
        label: 'Price',
        width: '128px',
        parameter: 'q[statuses_cont]'
    },
    {
        id: ADDRESS_COLUMN_ID,
        label: 'Address',
        width: '108px',
        parameter: 'q[address.postcode_or_address.first_line_or_address.second_line_cont]'
    },
    {
        id: CREATED_TIME_COLUMN_ID,
        label: 'Created time',
        width: 'auto'
    }
];

export const PENDING_ORDER_TABLE_COLUMNS_CONFIG = [
    {
        id: ID_LINK_COLUMN_ID,
        label: 'Id/Link',
        width: '115px'
    },
    {
        id: RESTAURANT_COLUMN_ID,
        label: 'restaurant',
        width: 'auto'
    },
    {
        id: ADDRESS_COLUMN_ID,
        label: 'Address',
        width: '108px'
    },
    {
        id: CUSTOMER_COLUMN_ID,
        label: 'Customer',
        width: 'auto'
    },
    {
        id: CREATED_TIME_COLUMN_ID,
        label: 'Created time',
        width: 'auto'
    }
];
