export const sizes = {
    input: {
        height: '28px'
    },
    button: {
        height: {
            menu: '42px',
            outline: '40px',
            solid: '32px',
            login: '28px',
            loginGhost: '24px'
        }
    },
    popover: {
        maxWidth: {
            info: '200px',
            menu: '257px',
            infoLegend: '280px',
            logs: '100%'
        },
        mobileLineHeight: '17px'
    },
    loginPage: {
        form: {
            maxW: '300px'
        }
    }
};
