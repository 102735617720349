import { Box } from '@chakra-ui/layout';
import React from 'react';

export const FeastBottomStrokes = () => (
    <Box position="absolute" bottom="0" left="0" display={{ base: 'none', lg: 'initial' }}>
        <svg width="829" height="390" viewBox="0 0 829 390" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-99.1474 359.557L150.776 232.215" stroke="#0D265B" strokeWidth="40" strokeLinecap="round" />
            <path d="M-328.012 574.795L492.063 156.946" stroke="#0D265B" strokeWidth="40" strokeLinecap="round" />
            <path d="M-39.2901 542.66L780.785 124.811" stroke="#0D265B" strokeWidth="40" strokeLinecap="round" />
        </svg>
    </Box>
);
