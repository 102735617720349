import React from 'react';
import { Button, VStack } from '@chakra-ui/react';
import { RestaurantDetails, CustomerDetails, DriverDetails, OtherDetails, LogDetails } from './components';
import { NotesDetails } from './components/NotesDetails';

interface OrderDetailsProps {
    order: any;
}

export const DetailsBody = ({ order }: OrderDetailsProps) => {
    return (
        <VStack w="95%" spacing={0} pl={1} alignItems="center">
            <RestaurantDetails order={order} />
            <CustomerDetails order={order} />
            <NotesDetails order={order} />
            <DriverDetails order={order} />
            <OtherDetails order={order} />
            <LogDetails logs={order.logs} />
            <VStack w="full" justifyContent="flex-start" py={3} px={1} alignItems="flex-start">
                <Button variant="outline" w="full">
                    OK
                </Button>
            </VStack>
        </VStack>
    );
};
