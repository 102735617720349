import { BaseEpic } from '../../store';
import { combineEpics, Epic } from 'redux-observable';
import { filter, map, merge, mergeMap, of, takeUntil } from 'rxjs';
import { initializeStatuses, statusChanged, unwatchDeviceLiveStatus, watchDeviceLiveStatus } from './slice';
import { deviceStatusAdded$, deviceStatusOnce$, deviceStatusChanged$ } from './api';

const epics: Record<string, BaseEpic> = {
    getDeviceLiveStatusEpic: (action$) =>
        action$.pipe(
            filter(watchDeviceLiveStatus.match),
            mergeMap(() => {
                const collectionPrefix = process.env.REACT_APP_FIREBASE_RTDB_PREFIX as string;
                return deviceStatusOnce$(collectionPrefix).pipe(
                    mergeMap(({ lastId, statuses }) => {
                        return merge(
                            of(statuses).pipe(map(initializeStatuses)),
                            deviceStatusAdded$(collectionPrefix, lastId).pipe(map(statusChanged)),
                            deviceStatusChanged$(collectionPrefix).pipe(map(statusChanged))
                        );
                    }),
                    takeUntil(action$.pipe(filter(unwatchDeviceLiveStatus.match)))
                );
            })
        )
};

export const deviceLiveStatusEpics = combineEpics(...Object.values(epics)) as Epic;
