import { fromPairs, path, map as rMap, values, max, reduce } from 'ramda';
import { map, Observable } from 'rxjs';
import { DeliveryStatus } from '../../types';
import { injectEndpoints, tostifyOnQueryStarted } from '../../api/service';
import { childEvents, getOnceStream, getQuery, getRef, orderByChild } from '../../firebase';
import { DataSnapshot, startAfter } from '@firebase/database';

export const liveOrdersOnce$ = (collectionPrefix: string) =>
    getOnceStream(getRef(`${collectionPrefix}/live_orders`)).pipe(
        map((snapshot) => {
            const value = snapshot.val() ?? {};
            const timestamps = values(rMap(path(['timestamp']), value));
            const lastTimestamp = (reduce(max, 0, timestamps) ?? 0) as number;
            return { lastTimestamp, value };
        })
    );

export const ordersAdded$ = (collectionPrefix: string, lastTimestamp: number) => {
    const query = getQuery(`${collectionPrefix}/live_orders`, orderByChild('timestamp'), startAfter(lastTimestamp));
    return mapToOrder(childEvents(query, { child_added: true }));
};

export const ordersChanged$ = (collectionPrefix: string) => {
    const query = getQuery(`${collectionPrefix}/live_orders`);
    return mapToOrder(childEvents(query, { child_changed: true }));
};

export const ordersRemoved$ = (collectionPrefix: string) => {
    const query = getQuery(`${collectionPrefix}/live_orders`);
    return mapToOrder(childEvents(query, { child_removed: true }));
};

const mapToOrder = (obs: Observable<DataSnapshot>) =>
    obs.pipe(
        map((snapshot) => {
            const details = snapshot.val();
            const id = snapshot.key as string;

            return { id, details };
        })
    );

export const liveOrdersApi = injectEndpoints({
    endpoints: (builder) => ({
        getFilteredOrders: builder.query<any, string>({
            query: (params) => {
                const urlParams = new URLSearchParams(params).toString();
                return {
                    url: `/orders_list?page=1&per=25&${urlParams}`,
                    method: 'get'
                };
            },
            onQueryStarted: tostifyOnQueryStarted(undefined, 'Failed to filter orders'),
            transformResponse: (response: any) => {
                return fromPairs(response.map((order: any) => [order.id, order]));
            }
        })
    })
});

export const { useLazyGetFilteredOrdersQuery } = liveOrdersApi;

// TODO to be tested
export const getDeliveryStatus = (order: any): DeliveryStatus => {
    if (order?.restaurant?.no_delivery === true) {
        return DeliveryStatus.handledByRestaurant; // ok
    }

    if (order.delivered === true) {
        return DeliveryStatus.completed; //ok
    }

    // TODO check - even if delivered then condition here is true
    if (order?.pickup_started === true && order?.pickup_completed === false) {
        return DeliveryStatus.pickupInProgress;
    }

    if (order?.delivery_started == true) {
        return DeliveryStatus.pickupDone;
    }

    return DeliveryStatus.processPending;
};

export const stuartCancelationStatusExists = (stuart: any) => {
    const cancellations = stuart?.cancellations;

    if (!Array.isArray(cancellations)) {
        return false;
    }

    return cancellations.filter((cancellation) => cancellation?.reasonKey === 'package_not_ready').length > 0;
};
