import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { Table, Tbody, Thead, Tr, Text, Flex, Th, Button, Box } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { FilterInput } from './FilterInput';
import { ClearFiltersButton } from './ClearFiltersButton';
import { useDispatch } from 'react-redux';
import { clearFilters, updateFilters } from '../../slices/orders/slice';

type OrderTableProps = {
    children: ReactNode;
    columns: { label: string; width: string; id: string; parameter?: string }[];
};

export type FormValues = {
    ID_LINK_COLUMN_ID: string;
    RESTAURANT_COLUMN_ID: string;
    CUSTOMER_COLUMN_ID: string;
    ORDER_TYPE_COLUMN_ID: string;
    DELIVERY_COLUMN_ID: string;
    ADDRESS_COLUMN_ID: string;
    PRICE_COLUMN_ID: string;
};

export const OrderTable = ({ children, columns }: OrderTableProps) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const onSubmit = (data: FormValues) => {
        dispatch(updateFilters(data));
    };

    useEffect(() => {
        return () => {
            dispatch(clearFilters());
            methods.reset();
        };
    }, []);

    return (
        <Box position="relative">
            <FormProvider {...methods}>
                <ClearFiltersButton />

                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Table variant="orders">
                        <Thead>
                            <Tr>
                                {columns.map(({ label, width, id, parameter }) => (
                                    <Th key={label} px={0.5} py={3} width={width}>
                                        <Flex direction="column">
                                            <Text casing="uppercase" py={2.5} whiteSpace="nowrap">
                                                {label}
                                            </Text>
                                            <FilterInput name={id} parameter={parameter} />
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>

                        <Tbody>{children}</Tbody>
                    </Table>

                    <Button type="submit" display="none" />
                </form>
            </FormProvider>
        </Box>
    );
};
