import * as React from 'react';
import { Tabs as ChakraTabs, TabList, TabPanels, Tab, TabPanel, Text, Flex, Center } from '@chakra-ui/react';
import { Icon } from '../Icon';
import { TabsType } from './TabsType';

export const MobileTabs = ({ tabs }: { tabs: TabsType }) => {
    return (
        <ChakraTabs w="full" isLazy>
            <Flex justify="space-between">
                <TabList>
                    {tabs.map(({ label }) => (
                        <Tab py={3.5} key={label}>
                            <Text fontWeight="bold" casing="uppercase">
                                {label}
                            </Text>
                        </Tab>
                    ))}
                </TabList>
                <Center justifySelf="end" mr={5}>
                    <Icon name="SearchIcon" size={5} />
                </Center>
            </Flex>

            <TabPanels>
                {tabs.map(({ content, label }) => (
                    <TabPanel px={0} key={label}>
                        {content}
                    </TabPanel>
                ))}
            </TabPanels>
        </ChakraTabs>
    );
};
