export const greyScheme = {
    [100]: '#C7CACD',
    [200]: '#EEEEEE',
    [300]: '#F2F4F8',
    [400]: '#F6F6F6',
    [500]: '#F9F9FA',
    [600]: '#636363',
    [700]: '#7E7E7E' // TODO probably to be reordered
};

export const blueScheme = {
    [100]: '#091D46',
    [200]: '#007EBF',
    [300]: '#B8E5F9'
};

// TODO check scheme with Szop
export const yellowScheme = {
    [30]: '#FFFFE0',
    [50]: '#F1C537',
    [100]: '#F2C741',
    [200]: '#FFD245',
    [300]: '#FFC100'
};
// Generated using https://smart-swatch.netlify.app/#67B831
export const greenScheme = {
    50: '#edfce3',
    100: '#d2f0bf',
    200: '#b7e498',
    300: '#9ad971',
    400: '#7fcf4a',
    500: '#67B831',
    600: '#4e8d25',
    700: '#366518',
    800: '#1f3d0b',
    900: '#051600'
};

export const redScheme = {
    50: '#ffe3e3',
    100: '#ffb3b4',
    200: '#fc8384',
    300: '#F94D4C',
    400: '#f72422',
    500: '#dd0d08',
    600: '#ad0706',
    700: '#7c0303',
    800: '#4c0000',
    900: '#1f0000'
};

export const baseColors = {
    green: greenScheme,
    blue: blueScheme[100],
    red: redScheme,
    yellow: yellowScheme[100],

    grey1: greyScheme[200],
    grey2: greyScheme[300],
    grey3: greyScheme[100],
    grey4: greyScheme[400],

    white: '#FFFFFF'
};

export const deliveryStatus = {
    handledByRestaurant: yellowScheme[300],

    processPending: baseColors.grey3,
    pickupInProgress: blueScheme[300],
    pickupDone: blueScheme[200],
    completed: greenScheme[500],

    cancelled: redScheme[300]
};

// TODO check how we can create TS types of it
export const fonts = {
    primary: blueScheme[100],
    secondary: greyScheme[600],
    alert: '#FF3312',
    white: baseColors.white,
    yellow: yellowScheme[50],
    placeholder: greyScheme[700]
};

export const overlays = {
    modal: 'rgba(255, 255, 255, 0.2)'
};

export const gradients = {
    loginPage: {
        background: 'radial-gradient(67.84% 71.28% at 50% 0%, #0E3166 0%, #091D46 100%)',
        circle: 'radial-gradient(#254994 0%, rgba(37, 73, 148, 0.0001) 100%);'
    }
};

export const colors = {
    ...baseColors,

    greyScheme,
    blueScheme,
    yellowScheme,

    baseColors,
    deliveryStatus,

    fonts,
    overlays,
    gradients
};
