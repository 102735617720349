import React from 'react';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { isNil } from 'ramda';
import { Separator } from '../../../../components/Separator';
import { CallButton, CallButtonVariants } from '../../../../components/CallButton';
import { EmailActions } from './EmailActions';

interface CustomerDetailsProps {
    order: any;
}

export const CustomerDetails = ({ order }: CustomerDetailsProps) => {
    const { id: userId, first_name = '', last_name = '', phone } = order?.user ?? {};
    const { first_line = '', postcode = '', second_line = '' } = order?.address ?? {};
    const { main_code = '' } = order?.hotel ?? {};
    const { id: orderId } = order ?? {};

    return (
        <>
            <Separator label="CUSTOMER" />
            <HStack w="full" justifyContent="space-between" py={0} pl={2} alignItems="center">
                <Stack direction="row" alignItems="center">
                    <Text fontWeight="normal">{`${first_name} ${last_name}`}</Text>
                    <Text fontWeight="bold">{main_code}</Text>
                </Stack>
                <CallButton phone={phone} variant={CallButtonVariants.default} />
            </HStack>
            <HStack w="full" justifyContent="flex-start" py={0} pl={2} pr={10} alignItems="center">
                <Text>
                    {`${first_line}`}
                    <br />
                    {`${second_line} ${postcode}`}
                </Text>
            </HStack>
            {!isNil(userId) && !isNil(orderId) && <EmailActions orderId={orderId} userId={userId} />}
        </>
    );
};
