import { isNil, mapObjIndexed, isEmpty } from 'ramda';
import { ORDER_TABLE_COLUMNS_CONFIG } from '../../containers/Dashboards/DesktopDashboard/const';
import { Order, Orders, PriceComponents } from './types';

export const mapIdToQueryParam = (id: any) => {
    const column = ORDER_TABLE_COLUMNS_CONFIG.find((el) => el.id === id);
    return column?.parameter;
};

export const combineOrders = (mainOrder: Order, linkedOrder: Order | undefined): Order => {
    if (isNil(linkedOrder)) {
        return mainOrder;
    }
    const { logs, cancelled, address, price_cents, price_components, id, payment_method, paid } = mainOrder;
    const { note_for_restaurant, note_for_driver } = address;

    return {
        ...mainOrder,
        id: `${id}${linkedOrder.id}`,
        logs: ['Main order logs:', ...logs, 'Belonging order logs:', ...linkedOrder.logs],
        cancelled: cancelled || linkedOrder.cancelled,
        paid: paid && linkedOrder.paid,
        payment_method: `Main order:\n${payment_method}\nBelonging order:\n${linkedOrder.payment_method}`,
        address: {
            ...address,
            note_for_driver: `${note_for_driver ?? ''}${
                !isEmpty(note_for_driver) && !isEmpty(linkedOrder.address.note_for_driver) ? '\n\n' : ''
            }${linkedOrder.address.note_for_driver ?? ''}`,
            note_for_restaurant: `${note_for_restaurant ?? ''}${
                !isEmpty(note_for_restaurant) && !isEmpty(linkedOrder.address.note_for_restaurant) ? '\n\n' : ''
            }${linkedOrder.address.note_for_restaurant ?? ''}`
        },
        price_cents: price_cents + linkedOrder.price_cents,
        price_components: mapObjIndexed(
            (value, key) => value + (linkedOrder?.price_components[key] ?? 0),
            price_components
        ) as PriceComponents
    };
};

export const sortOrderKeysByDate = (keys: string[], orders: Orders): string[] =>
    keys.sort((a: string, b: string) => Date.parse(orders[b].created_at) - Date.parse(orders[a].created_at));

export const handleOrderChange = (id: string, order: Order, state: any) => {
    const { extended_by_order, belongs_to_order } = order ?? {};

    if (!isNil(extended_by_order)) {
        const combinedOrderKey = id + extended_by_order;
        state.liveOrdersById[combinedOrderKey] = combineOrders(order, state.liveOrdersById[extended_by_order]);
        combineOrders(order, state.liveOrdersById[extended_by_order]);
    } else if (!isNil(belongs_to_order)) {
        const combinedOrderKey = belongs_to_order + id;
        state.liveOrdersById[combinedOrderKey] = combineOrders(state.liveOrdersById[belongs_to_order], order);
    }
};
