export const radii = {
    inputs: '5px',
    buttons: {
        sm: '5px',
        lg: '16px'
    },
    popover: {
        content: '10px',
        arrow: '0px 6px'
    },
    card: {
        container: '5px'
    }
};
