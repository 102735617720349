import React from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import { popoverContainerStylingProps } from '../const';
import { callAllHandlers } from '../../../utils';
import { BoxProps } from '@chakra-ui/layout';

interface MobileInfoPopOverProps {
    children: React.ReactNode;
    body: React.ReactNode;
    maxWidth: BoxProps['maxWidth'];
}

export const MobileInfoPopover = ({ children, body, maxWidth }: MobileInfoPopOverProps) => {
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const child: any = React.Children.only(children);
    const { props: childProps = {} } = child;
    const modalTriggerProps = {
        ...childProps,
        onClick: callAllHandlers(onModalOpen, childProps.onClick)
    };

    const modalTriggerElement = React.cloneElement(child, modalTriggerProps);

    return (
        <>
            {modalTriggerElement}

            <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered>
                <ModalOverlay bg="overlays.modal" />
                <ModalContent
                    {...popoverContainerStylingProps}
                    maxWidth={maxWidth}
                    fontSize="md"
                    lineHeight="popover.mobileLineHeight"
                    whiteSpace="initial"
                >
                    {body}
                </ModalContent>
            </Modal>
        </>
    );
};
