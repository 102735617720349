import { AxiosRequestConfig } from 'axios';

export const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;

export const API_CONFIG: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
};

export const AUTH_API_ENDPOINTS = {
    LOGIN: '/login',
    AUTHENTICATE: '/authenticate'
};

export const ACCESS_TOKEN_KEY = 'auth_token';
export const USER_ID_KEY = 'user_id';
export const USER_NAME_KEY = 'username';

export const ALL_TOKENS = [ACCESS_TOKEN_KEY, USER_ID_KEY, USER_NAME_KEY];
