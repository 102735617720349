export const Table = {
    variants: {
        orders: {
            root: {
                width: '""'
            },
            th: {
                textAlign: 'center'
            },
            tbody: {
                tr: {
                    '&:nth-of-type(even)': {
                        backgroundColor: 'grey4'
                    }
                }
            }
        }
    }
};
