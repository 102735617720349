import React from 'react';
import { IconButton, Text, VStack } from '@chakra-ui/react';
import { isNil } from 'ramda';
import { Icon } from '../../../../components/Icon';
import { OrderCardQuickActions, OrderCardQuickActionsType } from '../../../OrderCardQuickActions';
import { Separator } from '../../../../components/Separator';
import { ManagerInfo } from './ManagerInfo';
import { CallButtons } from './CallButtons';
import { SeparatorStack } from './SeparatorStack';

interface RestaurantDetailsProps {
    order: any;
}
export const RestaurantDetails = ({ order }: RestaurantDetailsProps) => {
    const { hotel, restaurant, user } = order;
    const { email } = user;
    const { name, address, primary_number } = restaurant;

    return (
        <>
            <Separator label="RESTAURANT" mt={-2} mb={-3} />
            <OrderCardQuickActions order={order} py={1} pl={2} pr={0} type={OrderCardQuickActionsType.details} />

            <SeparatorStack w="full" justifyContent="flex-start" pl={2} alignItems="center">
                {!isNil(primary_number) && <Text fontWeight="bold">{primary_number}</Text>}
            </SeparatorStack>

            {isNil(email) && (
                <SeparatorStack w="full" justifyContent="space-between" pl={2} alignItems="center">
                    <Text fontWeight="bold">{email}</Text>
                    <IconButton icon={<Icon name="MessageWithSquareIcon" size={6} />} aria-label="Call" />
                </SeparatorStack>
            )}
            <SeparatorStack w="full" justifyContent="flex-start" pl={2} alignItems="center">
                {!isNil(name) && <Text>{name}</Text>}
            </SeparatorStack>

            <SeparatorStack w="full" justifyContent="flex-start" pl={2} alignItems="center">
                {!isNil(address) && <Text>{address}</Text>}
            </SeparatorStack>

            {!isNil(hotel?.manager) && (
                <>
                    <Separator />
                    <VStack w="full" justifyContent="flex-start" pl={2} alignItems="left" spacing={0}>
                        <ManagerInfo order={order} />
                    </VStack>
                </>
            )}

            {!isNil(hotel) && !hotel.upfront_payment && (
                <>
                    <Separator />
                    <VStack w="full" justifyContent="flex-start" pl={2} alignItems="left">
                        <Text>Reception payment!</Text>
                    </VStack>
                </>
            )}

            <CallButtons order={order} />
        </>
    );
};
