export const Input = {
    baseStyle: {
        field: {
            borderColor: 'transparent',
            bg: 'greyScheme.500',
            boxShadow: 'inset 0px 6px 10px rgba(176, 179, 185, 0.383466)',
            borderRadius: 'inputs',
            _placeholder: {
                color: 'fonts.placeholder',
                fontSize: 'lg'
            },
            _invalid: {
                borderColor: 'fonts.alert',
                borderWidth: '1px'
            },
            _disabled: {
                bg: 'greyScheme.200'
            }
        }
    },
    variants: {
        primary: {
            field: {
                boxShadow: 'primaryInput',
                h: 'input.height',
                fontSize: 'lg'
            }
        },
        secondary: {
            field: {
                boxShadow: 'secondaryInput',
                h: 'input.height',
                fontSize: 'lg'
            }
        }
    },
    defaultProps: {
        variant: 'primary'
    }
};
