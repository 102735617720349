import { isEmpty, isNil, join, reject } from 'ramda';

export * from './createErrorToast';
export * from './ScrollToTop';

// eslint-disable-next-line @typescript-eslint/ban-types
export type FunctionArguments<T extends Function> = T extends (...args: infer R) => any ? R : never;

export function callAllHandlers<T extends (event: any) => void>(...fns: (T | undefined)[]) {
    return function func(event: FunctionArguments<T>[0]) {
        fns.some((fn) => {
            fn?.(event);
            return event?.defaultPrevented;
        });
    };
}

export const formatPrice = (priceValue: number): string => {
    const beforeDecimal = Math.floor(priceValue / 100);
    const afterDecimal = String(priceValue % 100).padEnd(2, '0');

    return `£${beforeDecimal}.${afterDecimal}`;
};

export const isNilOrEmpty = (element: any) => isNil(element) || isEmpty(element) || !element;

export const joinByNewLine = (arr: any[]): string => join('\n', reject(isNilOrEmpty, arr));

export const formatOrderCardDate = (date: string): string => {
    const o = new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: 'short',
        day: '2-digit'
    });
    return o.format(Date.parse(date)).split(' ').join('/');
};
export const formatOrderCardTime = (date: string): string => {
    const o = new Intl.DateTimeFormat('en-GB', {
        hour: '2-digit',
        minute: '2-digit'
    });
    return o.format(Date.parse(date));
};

export const formatRefundsDateTime = (date: string): string => {
    //On Friday, June 4th, 2021
    const o = new Intl.DateTimeFormat('en-GB', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });
    return o.format(Date.parse(date));
};
