import * as React from 'react';
import { ReactNode } from 'react';
import { Td, Tr } from '@chakra-ui/react';

type OrderTableRowProps = {
    children: Array<ReactNode>;
    isHighlightedRow?: boolean;
};

export const OrderTableRow = ({ children }: OrderTableRowProps) => {
    const lastIndex = children.length - 1;

    return (
        <Tr>
            {children.map((content, idx) => (
                <Td
                    py={2}
                    px={2}
                    key={idx}
                    _after={idx !== lastIndex ? separatorPseudoElementStyle : undefined}
                    position="relative"
                    whiteSpace="nowrap"
                >
                    {content}
                </Td>
            ))}
        </Tr>
    );
};

OrderTableRow.defaultProps = {
    isHighlightedRow: false
};

const separatorPseudoElementStyle = {
    content: '""',
    width: '2px',
    height: 'calc(100% - 10px)',
    bg: 'grey1',
    position: 'absolute',
    top: '5px',
    right: '-1px'
};
