import React from 'react';
import { usePutChangeCallRequestMutation } from './api';
import { CallButton, CallButtonProps } from '../../components/CallButton';

export interface CallRequestProps extends CallButtonProps {
    callRestaurant: boolean;
    orderId: string;
}

export const CallRequest = ({ orderId, callRestaurant, ...props }: CallRequestProps) => {
    const [updateStatus] = usePutChangeCallRequestMutation();

    return (
        <CallButton
            onClick={() => {
                if (callRestaurant) {
                    updateStatus({ orderId, callRestaurant: false });
                }
            }}
            {...props}
        />
    );
};
